import React from 'react';
import { NavLink } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import PageBanner from '../components/PageBanner';
import LinkMailto from '../components/LinkMailto';
import TermsPrivacyUpdatedDate from '../components/TermsPrivacyUpdatedDate';


const PrivacyPolicy = () => {
    return (
        <div>
            <PageBanner title="openDoctor Privacy Policy" />
            <Container fluid className='p-5'>
                <Row className='justify-content-md-center'>
                    <Col md={8}>
                        <h6 className="fw-semibold">Summary</h6>
                        <ul className="bullets">
                            <li><span className="trademark">openDoctor</span> respects your privacy. <span className="trademark">openDoctor</span> does not sell, lease, or rent your individual-level Personal Information without explicit consent. </li>
                            <li>We are committed to providing a secure, user-controlled environment for our Services. </li>
                        </ul>
                        <h6 className="fw-semibold">Privacy Policy</h6>
                        <p><span className="trademark">openDoctor</span> respects the privacy of our online visitors and has constructed and administers this Site accordingly. We treat all submitted personal data as private and confidential. We do not sell or otherwise transfer this information to any third party organization.</p>
                        <p>In order to access certain areas of the Site, <span className="trademark">openDoctor</span> may require visitors to provide certain information that personally identifies them ("Personal Information"). Personal Information includes the following categories of information: </p>
                        <ul className="bullets">
                            <li> Contact Data (such as e-mail address, phone number and <span className="trademark">openDoctor</span> password); </li>
                            <li>Demographic Data (such as gender, date of birth and zip code); </li>
                            <li> Insurance Data (such as insurance carrier and insurance plan); and </li>
                            <li>Medical Data (such as previous doctors ("Providers") visited, visit reason, visit date, and other medical information users choose to share with <span className="trademark">openDoctor).</span> </li>
                        </ul>
                        <p> Regardless of how you communicate with <span className="trademark">openDoctor,</span> for example, e-mail or letter, any information provided in such communication may be collected as Personal Information.</p>
                        <p><span className="trademark">openDoctor</span> only gathers personal information when voluntarily submitted by a visitor. Any and all such information collected by <span className="trademark">openDoctor</span> is used for internal business purposes only. <span className="trademark">openDoctor</span> will not allow such collected information to be sold, traded or viewed by any third party. The only exception to this are when you give us your consent to share this information, when we need to share your information to provide a service that you have requested (such as an online appointment with one or more doctors subscribing to our service), or when we are required by law to disclose information. You will not receive e-mail as a result of visiting this Site unless it is an express part of a service we provide, such as a reminder of an appointment you have scheduled, or you request it.</p>
                        <p><span className="trademark">openDoctor</span> does not collect information from children under the age of 13 in accordance with the Children's Online Privacy Protection Act ("COPPA") as discussed below. If you are a parent or legal guardian of a minor child, you may, in compliance with the <NavLink to="/terms-conditions">Terms of Service</NavLink>, use the Site or Services on behalf of such minor child. Any information that you provide while using the Site or Service on behalf of your minor child will be treated as Personal Information as otherwise provided herein.</p>
                        <p> Children's Online Privacy Protection Act (COPPA) severely restricts what information can be collected from children under the age of 13. For this reason, children under the age of 13 in the United States are prohibited from using the Website. The Website is not directed at children, and <span className="trademark">openDoctor</span> does not knowingly collect any information from individuals under the age of 13. If we learn that we have received any information from an individual under the age of 13, we will use that information only to respond directly to that child (or a parent or legal guardian) to inform him or her that he or she cannot use the Services and subsequently we will delete that information from our own servers.</p>
                        <p><span className="trademark">openDoctor</span> may use "cookie" technology with respect to this Site, but makes such use only to obtain non-personal information from online visitors. This information will be used only as necessary to permit functionality of the Site or to enhance the user's online experience. For example, we may track the number of visitors using portions or features of the Site in order to improve the Site and the manner in which it is used and to develop new features.</p>
                        <p><span className="trademark">openDoctor</span> is a licensee of the GoDaddy SSL Certificate Secure Site Seal. GoDaddy is a well established Certification Authority and SSL Certificate Provider. <span className="trademark">openDoctor</span> is committed to providing you a secure Site and GoDaddy provides the strongest SSL possible to secure transactions. This Privacy Policy is only applicable to the Site www.openDr.com.</p>
                        <p> The security of your Personal Information is important to us. We follow generally accepted industry standards to protect the Personal Information submitted to us, both during transmission and once we receive it. For example, when you enter sensitive information on our Site, we encrypt that information using secure socket layer technology (SSL).</p>
                        <p>Doctors, dentists and any other health care provider who provides medical and other health services as defined in section 1861(s) of 42 U.S.C. 1395x(s), and any other person or organization who furnishes, bills, or is paid for health care in the normal course of business, and their respective employees and agents should be particularly aware of their obligations of patient confidentiality, including without limitation their obligations under the Health Insurance Portability and Accountability Act ("HIPAA"). <span className="trademark">openDoctor</span> does not have, and will not accept, any obligations of confidentiality with respect to any communications other than those expressly stated in this Privacy Policy, <span className="trademark">openDoctor</span> Terms of Service, and the HIPPA Business Associate provisions of the <span className="trademark">openDoctor</span> Subscription Agreement that <span className="trademark">openDoctor</span> enters into with health care providers.</p>
                        <p>Although we make good faith efforts to store Personal Information in a secure operating environment that is not open to the public, you should understand that there is no such thing as complete security, and we do not guarantee that there will be no unintended disclosures of your Personal Information. If we become aware that your Personal Information has been disclosed in a manner not in accordance with this Privacy Policy, we will use reasonable efforts to notify you of the nature and extent of the disclosure (to the extent we know that information) as soon as reasonably possible and as permitted by law. You must promptly notify us if your Contact Data is lost, stolen, or used without permission. In such an event, we will remove that Contact Data from your account and update our records accordingly</p>
                        <p> This policy describes the information practices of <span className="trademark">openDoctor</span> in connection with this Site. This policy does not govern web sites of other parties to which we may provide links. We do not control and are not responsible for the privacy practices of or the data available on the sites of third parties, and we urge you to evaluate the soundness of these practices and data for yourself.</p>
                        <p>If you have any comments, concerns or questions about this Privacy Policy, please contact us at <LinkMailto label="userssupport@opendr.com" mailto="mailto:userssupport@opendr.com" />. </p>
                        <p> or</p>
                        <div className="agent">
                            <span className="trademark">openDoctor</span><br />
                            33 Park PL<br />
                            S110, New York, NY 10007<br />

                            <LinkMailto label="userssupport@opendr.com" mailto="mailto:userssupport@opendr.com" />
                            <br /><br />
                        </div>
                        <p> <span className="trademark">openDoctor</span> reserves the right to change this policy at any time without prior notice. Please be sure to check this page periodically for updates to this policy.</p>
                        <div className="lastUpdated">
                            <p> LAST UPDATED: <TermsPrivacyUpdatedDate /></p>
                            <p> Copyright (©) {new Date().getFullYear()}, <span className="trademark">openDoctors <span className="opDocTrade">24<span>7</span>, Inc</span>. All Rights Reserved.</span></p>
                        </div>
                        <p> Privacy is paramount for <span className="trademark">openDoctor.</span> All information received by <span className="trademark">openDoctor</span> over its online network is delivered over a secure socket layer. We maintain redundant electronic and physical security measures, which exceed the provisions and policies mandated by the Privacy Act, to safeguard all personal and public record data. Our Site has been certified to be secure by GoDaddy.</p>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default PrivacyPolicy