import React from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import PageBanner from '../components/PageBanner';
import NewsUpdatesDetailPage from '../components/NewsUpdatesDetailPage';
import NewsUpdatesBackButton from '../components/NewsUpdatesBackButton';

const NewsUpdatesNews4 = () => {
    const newsContent = {
        title: 'openDoctor lands Covia Health agreement',
        paragraphs: [
            <>
                October 13, 2016 - Software developer OpenDoctor has landed an agreement to provide Covia Health with its online patient scheduling software for Covia's mobile 3D mammography and bone densitometry services.
            </>,
            <>
                <div className='col-md-12 text-center'>
                    <NewsUpdatesBackButton />
                    <NavLink to="http://www.auntminnie.com/index.aspx?sec=ser&amp;sub=def&amp;pag=dis&amp;ItemID=115341" target="_blank"><Button className="button large orange mx-0 mx-md-3">READ THE FULL ARTICLE</Button></NavLink>
                </div>
            </>
        ],
    };

    return (
        <div>
            <PageBanner title="News and Updates" />
            <NewsUpdatesDetailPage title={newsContent.title} paragraphs={newsContent.paragraphs} />
        </div>
    )
}
export default NewsUpdatesNews4