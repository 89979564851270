import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const MidTitle = props => {
    return (
        <Container fluid>
            <Row className="justify-content-md-center text-center p-5">
                <Col md={8}>
                    <h4 className="mb-3">
                        {props.title}
                    </h4>
                    <div className="fs18">
                        {props.text}
                    </div>
                </Col>
            </Row>

        </Container>
    );

}

export default MidTitle;