import React from 'react';
import PageBanner from '../components/PageBanner';
import MidTitle from '../components/MidTitle';
import CareersContent from '../components/CareersContent';

const Careers = () => {
    return (
        <div>
            <PageBanner title="Careers" text="Let's bring healthcare into the 21st century" />
            <MidTitle text="At openDoctor, the world's most talented engineers, designers, and thought leaders are shaping the future of healthcare." />
            <CareersContent />
        </div>
    )
}

export default Careers