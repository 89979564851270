import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import PageBanner from '../components/PageBanner';
import { NavLink } from 'react-router-dom';

const BusinessAnalystIntern = () => {
    return (
        <div>
            <PageBanner title="Careers" text="Let's bring healthcare into the 21st century" />
            <Container fluid className='p-5'>  <div className='text-center pb-5'><h4 className='fw-semibold'>Business Analyst Intern</h4></div>
                <Row className='justify-content-md-center'>
                    <Col md={8} className="border-top pt-5">
                        <p>
                            To apply to this position, <a href="https://in.indeed.com/cmp/Opendr-Business-Services-Pvt.-Ltd.?from=mobviewjob&tk=1griuh7fpkj8k800&fromjk=6dd92b91cd4f256a&attributionid=mobvjcmp">click here</a>
                        </p>
                        <p>
                            Are you a candidate with a unique blend of business acumen and tech savvy? Business Analysts
                            build the bridge between business and technology, immersing themselves into the market to
                            understand problems and find innovative solutions. Be ready to navigate the complex
                            relationships that make the business world run and find the actionable insights that result in
                            great business decisions. </p>
                        <p>Your Role and Responsibilities </p>
                        <p>As a Business Analyst, you are required to work under a Sr. BA for documenting the existing
                            and new requirements with understanding of the product and various flows and scenarios.
                            Understand business requirements, breaking the problem statement into smaller modules,
                            storyboarding the business requirement as per Implementation Methodology, Translate
                            business requirements to efficient and flexible models.
                        </p>
                        <br />
                        <h6 className='fw-semibold'>
                            Position Responsibilities:
                        </h6>
                        <ul>
                            <li>
                                Understand the scope of the tasks with logical ability to design the basic structure of solution at high level
                            </li>
                            <li>
                                Write documents of business process and implementations
                            </li>
                            <li>
                                Analyzing and evaluating the current business processes a company has and identifying areas of improvement
                            </li>
                            <li>
                                Researching and reviewing up-to-date business processes and new IT advancements to make systems more modern
                            </li>
                            <li>
                                Collaborating with users and stakeholders
                            </li>
                            <li>
                                Ensure Hipaa compliance from both a Technology and Operational standpoint
                            </li>
                            <li>
                                Refine requirements of User Stories created by the Product Owner to ensure the development team understands the business need and acceptance criteria prior to the beginning of each sprint
                            </li>
                            <li>
                                Refine solutions for Bug fixes created by the QA team once the developers identify the solution
                            </li>
                            <li>
                                Serve as the liaison between the developers and the Product Owner when there are technical blockers or additional questions that need clarification
                            </li>
                            <li> Coordinate UX Design &amp; UX Development work priorities with input from the Product Owner  </li>
                            <li> Work on various kinds of diagrams </li>
                            <li> Creating and maintaining various project life cycles docsInitiation document </li>
                        </ul>
                        <p>System Requirements Specifications document</p>
                        <p>Business requirement document</p>
                        <p>Functional requirement document</p>
                        <p>Requirements Traceability Matrix</p>
                        <p>Use case Specifications document</p>
                        <p>Change Request Document</p>
                        <p>Gap Analysis Document</p>
                        <br />
                        <h6 className='fw-semibold'>Qualification:</h6>
                        <ul>
                            <li>Minimum QualificationsGraduate/ Post Graduate </li>
                            <li>Thrives in pressure situations and able to prioritize multiple priorities</li>
                            <li>Familiarity with AGILE/SCRUM is a value add</li>
                            <li>Exceptional analytical and problem-solving skills</li>
                            <li>Excellent documentation skills</li>
                            <li>Strong, interpersonal communication skills are essentia.</li>
                            <li>Work well under pressure and you are not risk averse and comfortable taking on challenges</li>
                            <li>Excellent planning, organizational, and time management skills</li>
                            <li>Competency in Microsoft applications including Word, Excel.</li>
                            <li>Qualified candidates must be up to date on the latest trends in technology </li>
                        </ul>
                        <p>If you thrive in a dynamic, collaborative workplace, we provide an environment where you will
                            be challenged and inspired every single day. And if you relish the freedom to bring creative,
                            thoughtful solutions to the table, there&#39;s no limit to what you can accomplish here.</p>
                        <br />
                        <p>
                            <b>Job Type:</b>  Full-time, Fresher, Internship, Contractual / Temporary, Volunteer<br /><br />
                            <b>Contract length:</b> 6 months
                            <br /><br />

                            <b>Salary:</b> Up to ₹10,000.00 per month
                        </p>
                        <br />
                        <h6 className='fw-semibold'>Benefits:</h6>
                        <ul>

                            <li>
                                Paid time off
                            </li>
                        </ul>
                        <br />
                        <h6 className='fw-semibold'>Schedule:</h6>
                        <ul>
                            <li>
                                Day shift
                            </li>
                            <li>
                                Flexible shift
                            </li>
                            <li>
                                Monday to Friday
                            </li>
                        </ul>
                        <br />
                        <h6 className='fw-semibold'> Application Question(s):</h6>
                        <ul><li> Do you have basic coding knowledge?</li><li> Do you know any one programming language, if yes which one?</li></ul><br />
                        <h6 className='fw-semibold'>  Education:</h6>
                        <ul>
                            <li> Diploma (Preferred)</li></ul>
                        <br />
                        <NavLink to="/careers"><Button variant="outline-primary" id="button-addon1">  Back to all Jobs </Button></NavLink>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default BusinessAnalystIntern