import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PageBanner from '../components/PageBanner';
import DigitalFormsCheckin from '../images/openDoctor-Lite-Increase-conversions.jpg';

const DigitalFormsCheckIn = () => {
    return (
        <div>
            <PageBanner title="openDoctor Digital Forms and Check-In Solutions" text="" />
            <Container fluid className='p-5'>
                <Row className='justify-content-md-center'>
                    <Col lg={8}>
                        <h6 className='fw-semibold'>openCheck-In: In-office appointment check-in</h6>
                        <p>openDoctor's proprietary patient check-in feature. Patients can complete digital registration forms from their own mobile device or a provided device.</p>
                        <br />
                        <h6 className='fw-semibold'>openForms: Online registration, patient smart forms</h6>
                        <p>Engage patients digitally to complete intake forms and upload supporting documents.</p>
                        <br />
                        <h6 className='fw-semibold'>Check-In: Complete intake process at the point of service</h6>
                        <p>openDoctor's proprietary patient forms functionality to capture client's patient intake forms electronically.</p>

                        <br /><br />

                        <img alt="" src={DigitalFormsCheckin} />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default DigitalFormsCheckIn