import React from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import PageBanner from '../components/PageBanner';
import NewsUpdatesDetailPage from '../components/NewsUpdatesDetailPage';
import NewsUpdatesBackButton from '../components/NewsUpdatesBackButton';

const NewsUpdatesUpdates2 = () => {
    const newsContent = {
        title: 'Patient Engagement at the Forefront of openDoctor Partnership With Steinberg Diagnostic',
        paragraphs: [
            <>
                Feb 27th, 2019. As the first in Las Vegas to partner with openDoctor, Steinberg Diagnostic Medical Imaging (SDMI), a leading outpatient radiology practice, continues to be at the forefront of technology.
            </>,
            <>
                openDoctor's industry-leading platform will interface with SDMI's RIS system to automate patient calls, proactively communicating with patients. After an initial rollout in what is forecasted to be a long-term partnership, the service will be fully deployed for real-time online scheduling capabilities. The service will be fully deployed at all eight SDMI locations across all modalities such as CT, MRI and mammography.
            </>,
            <>
                SDMI was founded on the principle of practicing great medicine by putting patients first. "We have upheld these principles since our start in 1988," says Dr. David Steinberg, SDMI CEO. "SDMI is consistently the first to introduce new technology to southern Nevada and that doesn't stop at the equipment in their facilities. Through regular workflow analysis and staff education, SDMI is able to identify patient needs and implement innovative options like openDoctor."
            </>,
            <>
                "We look forward to seeing how this partnership with openDoctor can help patients interact digitally with our practice, all while providing the type of back-office automation required to compete in today's market," says Rachel Papka, chief health informatics officer, SDMI.
            </>,
            <>
                "Consumer awareness of digital health tools is now at an all-time high, combined with strong consumer expectations for the convenience offered in everyday industries," says Joseph Marino, CEO of openDoctor. "The changing healthcare landscape, like declining reimbursements and increasing pricing transparency, are forcing outpatient centers to better cater to healthcare consumers while reducing administrative costs. We are excited to provide a solution to tackle these current challenges for SDMI."
            </>,
            <>
                <p className='mb-2 fw-semibold'>About Steinberg Diagnostic Medical Imaging Centers:</p>
                In 1958, Dr. Leon Steinberg came to Las Vegas to help Sunrise Hospital build the first radiology center in southern Nevada. Through the experience in the hospital setting, he saw a need for a more patient-friendly option for medical imaging and aimed to build such a practice so with his son Dr. David Steinberg and Dr. Mark Winkler as his partners, Dr. Leon opened the first SDMI office in 1988. Their standards of excellence in patient care and technology have brought national and international recognition from the medical imaging and health care communities. Today, that commitment to deliver top-notch care wrapped around a positive patient experience has never been stronger. With more than 30 years under their belt, the number one priority in this family-owned business is to make sure that we treat every patient like a beloved member of our family.
            </>,
            <>
                <p className='mb-2 fw-semibold'>About openDoctor:</p>
                openDoctor is a cloud-based platform bridging the gap between complex scheduling environments of healthcare organizations and a consumer-friendly online experience. The openDoctor white-labeled solution helps healthcare systems and radiology practices maximize patient engagement through real-time online scheduling, appointment reminders, digital registration, online payments and other digital tools.
            </>,
            <>
                <div className='col-md-12 text-center'>
                    <NewsUpdatesBackButton />
                    <NavLink to="https://www.prnewswire.com/news-releases/patient-engagement-at-the-forefront-of-opendoctor-partnership-with-steinberg-diagnostic-300802886.html" target="_blank"><Button className="button large orange mx-0 mx-md-3">READ THE FULL ARTICLE</Button></NavLink>
                </div>
            </>
        ],
    };

    return (
        <div>
            <PageBanner title="News and Updates" />
            <NewsUpdatesDetailPage title={newsContent.title} paragraphs={newsContent.paragraphs} />
        </div>
    )
}
export default NewsUpdatesUpdates2