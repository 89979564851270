import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import PageBanner from '../components/PageBanner';
import { NavLink } from 'react-router-dom';

const ProjectManager = () => {
    return (
        <div>
            <PageBanner title="Careers" text="Let's bring healthcare into the 21st century" />
            <Container fluid className='p-5'>
                <div className='text-center pb-5'><h4 className='fw-semibold'>Project Manager</h4></div>
                <Row className='justify-content-md-center'>
                    <Col md={7} className="border-top pt-5">
                        <p>
                            To apply to this position, <a href="https://www.indeed.com/jobs?q=openDoctor&amp;l=New%20York%2C%20NY&amp;advn=3478792281627241&amp;vjk=4f3a50877912fd4c">click here</a>
                        </p>
                        <p>
                            We’re seeking a mid-level/entry-level project manager to join our client services team. The project manager works with some of the leading healthcare organizations in the country to implement our cloud-based patient engagement solution. This is a great opportunity to join a dynamic, quickly growing startup. You will take on a lot of responsibility early and your work will make a meaningful impact on our customers, patients across the country, and your career. You will join a team of fun, hardworking professionals working together to build a company and improve patient experience and access to healthcare. If you are interested and qualified, please send your current resume and cover letter for consideration.
                        </p>
                        <br />
                        <h5 className='fw-semibold'>
                            Position Responsibilities:
                        </h5>
                        <ul>
                            <li>
                                Manage all aspects of the openDoctor implementation from beginning to end, ensuring all necessary tasks are completed on time
                            </li>
                            <li>
                                Manage the project plan, identify and resolve issues and risks, and track and communicate overall project status
                            </li>
                            <li>
                                Lead meetings with the client to gather requirements, collect and validate data, and review project status and upcoming tasks
                            </li>
                            <li>
                                Understand clients’ strategic goals and priorities, and adapt project timelines and management approach accordingly
                            </li>
                            <li>
                                Analyze client data and workflows and translate into software configuration, including data mapping, system validation, and testing
                            </li>
                            <li>
                                Manage communications between the customer and development team to ensure that all requirements are understood and met
                            </li>
                            <li>
                                Identify opportunities for improvement to the software, and work with our development team to design enhancements
                            </li>
                            <li>
                                Develop implementation best practices, and enhance internal processes and documentation
                            </li>
                        </ul>
                        <br />
                        <h5 className='fw-semibold'>Requirements:</h5>
                        <ul>
                            <li>
                                Bachelor’s degree or greater (any major)
                            </li>
                            <li>
                                A history of academic and/or professional success
                            </li>
                            <li>
                                Superior organization skills and ability to multi-task
                            </li>
                            <li>
                                Excellent written and verbal communication
                            </li>
                            <li>
                                Ability to present to groups and to lead customer-facing meetings with confidence
                            </li>

                            <li>
                                Eligibility to work in the United States without visa sponsorship
                            </li>
                            <li>
                                Comfort in a relaxed, fun, and flat company culture
                            </li>
                            <li>
                                Ability to commute to our office in lower Manhattan
                            </li>
                            <li>
                                Software experience or training is not required. Instead, be hard working, a quick learner, and comfortable with new experiences and tasks.
                            </li>
                            <li>
                                Healthcare/Radiology experience a big plus
                            </li>
                            <li>
                                Job Type: Full-time
                            </li>
                        </ul>
                        <br />
                        <h5 className='fw-semibold'>Benefits:</h5>
                        <p>
                            401(k)<br />
                            401(k) matching<br />
                            Dental insurance<br />
                            Health insurance<br />
                            Paid time off<br />
                            Vision insurance
                        </p>
                        <br />
                        <h5 className='fw-semibold'>Schedule:</h5>
                        <p>Monday to Friday</p>
                        <br />
                        <h5 className='fw-semibold'>Supplemental Pay:</h5>
                        <p>Bonus pay</p>
                        <br />
                        <h5 className='fw-semibold'>Education:</h5>
                        <p>Bachelor’s (Preferred)</p>
                        <br />
                        <h5 className='fw-semibold'>Experience:</h5>
                        <p>
                            Microsoft Office: 1 year (Preferred)<br />
                            Project Management: 1 year (Preferred)
                        </p>
                        <br />
                        <h5 className='fw-semibold'>Work Location:</h5>
                        <p>
                            One location
                        </p>
                        <br />
                        <h5 className='fw-semibold'>This Company Describes Its Culture as:</h5>
                        <p>
                            Detail-oriented — quality and precision-focused<br />
                            Innovative — innovative and risk-taking<br />
                            Outcome-oriented — results-focused with strong performance culture<br />
                            Stable — traditional, stable, strong processes<br />
                            People-oriented — supportive and fairness-focused<br />
                            Team-oriented — cooperative and collaborative
                        </p>
                        <br />
                        <h5 className='fw-semibold'>Benefit Conditions:</h5>
                        <p>
                            Waiting period may apply
                        </p>
                        <br />
                        <h5 className='fw-semibold'>Work Remotely:</h5>
                        <p>
                            Temporarily due to COVID-19
                        </p>
                        <br />
                        <h5 className='fw-semibold'>COVID-19 Precaution(s):</h5>
                        <p>
                            Remote interview process<br />
                            Virtual meetings
                        </p>
                        <br />
                        <Button variant="outline-primary" id="button-addon1"> <NavLink to="/careers"> Back to all Jobs</NavLink> </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ProjectManager