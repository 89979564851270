import React from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import PageBanner from '../components/PageBanner';
import NewsUpdatesDetailPage from '../components/NewsUpdatesDetailPage';
import NewsUpdatesBackButton from '../components/NewsUpdatesBackButton';

const NewsUpdatesUpdates3 = () => {
    const newsContent = {
        title: "Why Patients Won't 'Request an Appointment' Much Longer",
        paragraphs: [
            <>
                Check out our top reasons online self-scheduling is a critical next step in any healthcare provider's digital strategy.
            </>,
            <>
                <div className='col-md-12 text-center'>
                    <NewsUpdatesBackButton />
                    <NavLink to="https://opendr.com/pdf/Stats_Story.pdf" target="_blank"><Button className="button large orange mx-0 mx-md-3">READ THE FULL ARTICLE</Button></NavLink>
                </div>
            </>
        ],
    };

    return (
        <div>
            <PageBanner title="News and Updates" />
            <NewsUpdatesDetailPage title={newsContent.title} paragraphs={newsContent.paragraphs} />
        </div>
    )
}
export default NewsUpdatesUpdates3