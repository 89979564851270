import React from 'react';
import { Col } from 'react-bootstrap';

const CareersBlock = props => {
    return (
        <Col lg={3} md={6}>
            <div className="text-center pt-3 mb-4">
                <div className="overflow mb-4">
                    <img src={props.imgsrc} alt="Image1" />
                </div>
                <div className="text-dark p-2">
                    <h6 className="fw-semibold mb-3">{props.title}</h6>
                    <p>{props.text}</p>
                </div>
            </div>
        </Col>
    )
}

export default CareersBlock