import React from 'react';
import { NavLink } from 'react-router-dom';
import PageBanner from '../components/PageBanner';
import NewsUpdatesDetailPage from '../components/NewsUpdatesDetailPage';
import NewsUpdatesBackButton from '../components/NewsUpdatesBackButton';

const NewsUpdatesContent1 = () => {
    const newsContent = {
        title: 'openDoctor and Steinberg Diagnostic Join Forces for Ordering and Scheduling Automation',
        paragraphs: [
            <>
                <strong>Steinberg Diagnostic Medical Imaging first in Southern Nevada to offer Online Scheduling for Radiology</strong>
            </>,
            <>
                NEW YORK, March 17, 2021 /PRNewswire/ — Steinberg Diagnostic Medical Imaging (SDMI), Las Vegas's leading outpatient radiology practice, has partnered with openDoctor to offer real-time online patient scheduling, allowing patients and referring physicians to schedule outpatient radiology appointments at each of their 9 locations.
            </>,
            <>
                Once SDMI obtains the order, the patient receives a text with a link to schedule online. The available services for online scheduling are X-Ray, Mammogram, Ultrasounds, MRI, CT and will expand to further services in the near future.
            </>,
            <>
                Referring providers are able to submit orders electronically via the openDoctor Referring Provider portal and immediately are able to schedule that order for their patient. In addition, referring providers are able to quickly access reports and images as well as track their referred patients through the scheduling and exam process.
            </>,
            <>
                “The results have been incredible, patients and physicians have quickly taken to the online option, and we see the number of appointments scheduled online increase daily.” States Rachel Papka, CHIO (Chief Health Informatics Officer). “We are hearing from both patients and physicians they love the ability to schedule at their own convenience versus the only option in the past was a phone call. Our online appointments have increased by 81% from our launch. We are excited for our plans this year to offer more online options for both patients and referring providers.”
            </>,
            <>
                “Consumer awareness of digital health tools is now at an all-time high, combined with strong consumer expectations for the convenience offered in everyday industries,” says Joe Marino, CEO of openDoctor. “The changing healthcare landscape like declining reimbursements and pricing transparency are forcing outpatient centers to better cater to healthcare consumers and referring provider relationships. We are excited to provide a solution to tackle these current challenges for SDMI.”
            </>,
            <>
                SDMI historically is a technology leader not only consistently installing state of the art equipment but continually adopting industry leading technology solutions to improve the interoperability between patients, referring providers and imaging centers. To learn more about the online scheduling process watch here: <NavLink to="https://c212.net/c/link/?t=0&amp;l=en&amp;o=3098858-1&amp;h=1687528232&amp;u=https%3A%2F%2Fyoutu.be%2FF3TjIj5Wb7o&amp;a=https%3A%2F%2Fyoutu.be%2FF3TjIj5Wb7o" rel="noreferrer noopener" target="_blank">https://youtu.be/F3TjIj5Wb7o</NavLink>
            </>,
            <>
                <strong>To Learn More About Steinberg Diagnostic Medical Imaging visit</strong> <NavLink to="https://c212.net/c/link/?t=0&amp;l=en&amp;o=3098858-1&amp;h=3846039526&amp;u=http%3A%2F%2Fsdmi-lv.com%2Fabout&amp;a=sdmi-lv.com%2Fabout" rel="noreferrer noopener" target="_blank">sdmi-lv.com/about</NavLink>
            </>,
            <>
                <p className='mb-2 fw-semibold'>About openDoctor:</p>
                openDoctor is a cloud-based platform bridging the gap between complex scheduling environments of healthcare organizations and a consumer-friendly online experience. The openDoctor helps healthcare systems and radiology practices maximize patient access and referring engagement automation.
            </>,
            <>
                <p className='mb-2 fw-semibold'>Press Contacts:</p>
                Austin Deer<br />
                Director Business Development<br />
                <NavLink to="mailto:adeer@opendr.com">adeer@opendr.com</NavLink>
            </>,
            <>
                Denielle Chong<br />
                Marketing Director<br />
                Steinberg Diagnostic Medical Imaging<br />
                <NavLink to="mailto:dchong@sdmi-lv.com">dchong@sdmi-lv.com</NavLink><br />
                702-732-6005
            </>,
            <>
                <div className='col-md-12 text-center'>
                    <NewsUpdatesBackButton />
                </div>
            </>
        ],
    };

    return (
        <div>
            <PageBanner title="News and Updates" />
            <NewsUpdatesDetailPage title={newsContent.title} paragraphs={newsContent.paragraphs} />
        </div>
    )
}
export default NewsUpdatesContent1