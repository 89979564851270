import React from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import PageBanner from '../components/PageBanner';
import NewsUpdatesDetailPage from '../components/NewsUpdatesDetailPage';
import NewsUpdatesBackButton from '../components/NewsUpdatesBackButton';

const NewsUpdatesUpdates1 = () => {
    const newsContent = {
        title: 'openDoctor Partners With Zwanger Pesiri for Real-Time Patient Online Scheduling',
        paragraphs: [
            <>
                March 5, 2019. Zwanger Pesiri, one of the nation's largest outpatient radiology practices and provider of subspecialty radiology services in the US, today announced it has chosen openDoctor to provide real-time online scheduling for new and existing patients.<br /><br />
                With their cutting edge patient scheduling engine, the openDoctor platform will interface with Fuji RIS system allowing patients to schedule complex appointment direct to the RIS internal schedule. openDoctor has successfully brought scheduling expectations to the forefront by allowing patients to book multiple appointment types, have a confirmed appointment at time of booking, and manage their appointments, reducing no shows and cancellations.
            </>,
            <>
                "The shift in healthcare to consumerism has created a need for our practice to adopt a solution that can provide patients with a simple, user friendly experience while accommodating our complex scheduling requirements and workflows," says Bob Day, COO of Zwanger. "openDoctor is the leader in patient access and engagement tools in radiology. Zwanger proudly focuses on the best patient experience and we are proud to offer the leading online experience to our loyal patients."
            </>,
            <>
                "We are excited to be partnering with an industry leader like Zwanger," says Joseph Marino, CEO of openDoctor. "The meteoric rise of patient awareness and utilization of digital tools nationally has come as no surprise. Consumer expectations from industries like travel, banking and retail have long succeed healthcare offerings. The momentum in 2018 has created a competitive advantage for radiology practices who have committed to digital transformation by mitigating the risk of losing market share."
            </>,
            <>
                About Zwanger Persiri: With over 65 years of experience servicing the greater New York City area, Zwanger is the gold standard for outpatient radiology services with a prime focus in the patient experience. Zwanger has 30 locations and 65 radiologists.
            </>,
            <>
                About openDoctor: openDoctor is a cloud-based platform bridging the gap between complex scheduling environments of healthcare organizations and a consumer-friendly online experience. The openDoctor white labeled solution helps healthcare systems and radiology practices maximize patient engagement through online scheduling, appointment reminders, digital registration, online payments and other digital tools.
            </>,
            <>
                <div className='col-md-12 text-center'>
                    <NewsUpdatesBackButton />
                    <NavLink to="https://www.ptcommunity.com/wire/opendoctor-partners-zwanger-pesiri-real-time-patient-online-scheduling" target="_blank"><Button className="button large orange mx-0 mx-md-3">READ THE FULL ARTICLE</Button></NavLink>
                </div>
            </>
        ],
    };
    return (
        <div>
            <PageBanner title="News and Updates" />
            <NewsUpdatesDetailPage title={newsContent.title} paragraphs={newsContent.paragraphs} />
        </div>
    )
}
export default NewsUpdatesUpdates1