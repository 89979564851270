import React, { useEffect, useState } from 'react';
import { SendEmail } from '../API';
import Form from 'react-bootstrap/Form';
import { Container, Row, Col, Button } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import InlineError from '../components/InlineError';
import Loading from '../components/Loading';
import { validateEmail, validateFullName, validateMessage, } from '../components/Validation';
import { toast } from 'react-toastify';
import Toast from '../components/Toast';

const InquiryForm = () => {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [fullNameError, setFullNameError] = useState();
    const [emailError, setEmailError] = useState();
    const [messageError, setMessageError] = useState();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [send, setSend] = useState();

    useEffect(() => {

        // *********** VALIDATION **********
        validateFullName({ fullName, setFullNameError });
        validateEmail({ email, setEmailError });
        validateMessage({ message, setMessageError });

        // ***********
        if (send) {
            toast.success(send.msg);
            setFullName("")
            setEmail("")
            setMessage("")
            setSend()
        }
    }, [fullName, email, message, send]);

    const submitHandler = (e) => {
        e.preventDefault();
        setButtonLoading(true);
        if (!fullNameError & !emailError & !messageError) {
            SendEmail({ fullName, email, message }).then(
                () => {
                    setButtonLoading(false);
                }
            );
        }
    };
    return (
        <>
            <Toast />

            {loading ? (
                <Loading />
            ) : (
                <span>hello</span>
            )};
            <div className='blue-bg-light p-5 mt-5'>
                <Container>
                    <Row className="justify-content-md-center p-5 mt-5">
                        <Col md={6}>
                            <div className="text-center">
                                <h4 className='mb-3'>Let's start with a conversation</h4>
                                <p>Please complete the form below to speak with <br />one of our scheduling experts</p>
                            </div>
                            <Form onSubmit={submitHandler}>
                                <FloatingLabel className='mb-4'
                                    controlId="floatingSelectGrid"
                                    label="Please select an expert"
                                >
                                    <Form.Select aria-label="">
                                        <option>Select</option>
                                        <option value="CEO">CEO</option>
                                        <option value="Head of Product">Head of Product</option>
                                        <option value="Head of Business Development">Head of Business Development</option>
                                    </Form.Select>
                                </FloatingLabel>

                                <Form.Floating className='mb-4'>
                                    <Form.Control value={fullName}
                                        onChange={(e) => setFullName(e.target.value)} id="fullName" type="text" placeholder="Your full name" required />
                                    <label htmlFor="fullName">Your full name</label>
                                    {fullNameError && <InlineError error={fullNameError} />}
                                    <Form.Control.Feedback type="invalid">Please enter your fullname.</Form.Control.Feedback>
                                </Form.Floating>

                                <Form.Floating className='mb-4'>
                                    <Form.Control value={email}
                                        onChange={(e) => setEmail(e.target.value)} id="emailAddress" type="email" placeholder="Your email address" required />
                                    <label htmlFor="emailAddress">Your email address</label>
                                    {emailError && <InlineError error={emailError} />}
                                    <Form.Control.Feedback type="invalid">Email address is required.</Form.Control.Feedback>
                                </Form.Floating>

                                <Form.Floating className='mb-4'>
                                    <Form.Control id="subject" type="text" placeholder="subject" />
                                    <label htmlFor="subject">Subject</label>
                                </Form.Floating>

                                <Form.Floating className='mb-4'>
                                    <Form.Control value={message}
                                        onChange={(e) => setMessage(e.target.value)} id="message" as="textarea" rows={3} placeholder="Type your message here..." required />
                                    <label htmlFor="message">Type your message here...</label>
                                    {messageError && <InlineError error={messageError} />}
                                </Form.Floating>


                                <div className='text-center'>
                                    <Button type="submit" variant="outline-primary" disabled={buttonLoading && true}>Send</Button>
                                    <br /><br />
                                    <small>Free consultation, no commitment required.</small>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default InquiryForm