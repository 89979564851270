import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import PageBanner from '../components/PageBanner';
import { NavLink } from 'react-router-dom';

const QualityAssuranceTester = () => {
    return (
        <div>
            <PageBanner title="Careers" text="Let's bring healthcare into the 21st century" />
            <Container fluid className='p-5'>
                <div className='text-center pb-5'><h4 className='fw-semibold'>Quality Assurance Tester</h4></div>
                <Row className='justify-content-md-center'>
                    <Col md={8} className="border-top pt-5">
                        <p>
                            To apply to this position, <a href="https://in.indeed.com/job/quality-assurance-tester-f7cf35cd5b486f6c">click here</a>
                        </p>
                        <p>
                            We're seeking a Quality Assurance Tester who has expertise with web-based, mobile and client server application testing. As a Quality Assurance Tester individual will heavily interface and work very closely with the Development Team, Project Management Office and business users. Position requires coordination, analytical and problem-solving skills, risks mitigation and assurance timely deliverables with best possible quality. Individuals will be responsible to analyse business and technical specification, test cases creation, test cases execution and defects identification. As a Quality Assurance Tester, the individual will be responsible to provide wide range of testing services including test cases creation and execution, and performing different types of testing (Functional, System, Integration, Regression, multi-Browser) The individual will be responsible to gather and analyse test data in support of business cases, proposed projects, and systems requirements. This will include writing test plan strategies and test procedures documents as well as test cases for positive and negative scenarios. The Quality Assurance Tester will apply proven analytical and problem-solving skills to help validate IT processes through careful testing in order to maximize the benefit of business investments in IT initiatives. The candidate will establish and utilize quality assurance best practices, methodologies and approaches for all projects. Time management, communication (verbal/written) skills and previous testing experience is preferred. Position requires attendance on various meetings, conference calls, walkthrough sessions and representing the Global QA Testing Services Department.
                        </p>
                        <p>
                            The position will report directly into Quality Manager.
                        </p>
                        <br />
                        <h5 className='fw-semibold'>
                            Position Responsibilities:
                        </h5>
                        <ul>
                            <li>
                                Analyse user requirements, functional specification and create detailed level test strategy, test plan and test cases.
                            </li>
                            <li>
                                Identify impact of changes based on the
                            </li>
                            <li>
                                Execute test cases, analyse results, identify and document defects. Report bugs to the development teams.
                            </li>
                            <li>
                                Review the RCA provided by the dev team to evaluate if the issue cause is correct.
                            </li>
                            <li>
                                Perform API tests using Postman tool
                            </li>
                        </ul>
                        <br />
                        <h5 className='fw-semibold'>Requirements:</h5>
                        <ul>
                            <li>
                                Proven experience as a Quality Assurance Tester or similar role
                            </li>
                            <li>
                                Experience in project management and QA methodology
                            </li>
                            <li>
                                Familiarity with Agile frameworks and regression testing is a plus
                            </li>
                            <li>
                                Ability to document and troubleshoot errors
                            </li>
                            <li>
                                Working knowledge of test management software (e.g. qTest, Zephyr) and SQL
                            </li>
                            Excellent communication skills
                            <li>
                                Attention to detail
                            </li>
                            <li>
                                Analytical mind and problem-solving aptitude
                            </li>
                            <li>
                                Strong organizational skills
                            </li>
                        </ul>
                        <br />
                        <h5 className='fw-semibold'>Benefits:</h5>
                        <p>
                            Health insurance<br />
                            Life insurance<br />
                            Paid time off
                        </p>
                        <br />
                        <h5 className='fw-semibold'>Schedule:</h5>
                        <p>Monday to Friday</p>
                        <br />
                        <h5 className='fw-semibold'>Supplemental Pay:</h5>
                        <p>Performance Bonus pay</p>
                        <br />
                        <h5 className='fw-semibold'>Experience:</h5>
                        <p>
                            2 years (Preferred)
                        </p>
                        <br />
                        <h5 className='fw-semibold'>Work Location:</h5>
                        <p>
                            One location
                        </p>
                        <br />
                        <h5 className='fw-semibold'>COVID-19 Precaution(s):</h5>
                        <p>
                            Remote interview process<br />
                            Virtual meetings
                        </p>
                        <br />
                        <Button variant="outline-primary" id="button-addon1"> <NavLink to="/careers"> Back to all Jobs</NavLink> </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default QualityAssuranceTester