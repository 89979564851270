import React from 'react';
import { Container } from 'react-bootstrap';
import MidTitle from '../components/MidTitle';
import CustomersLogo from '../components/CustomersLogo';
import CaseStudiesContent from '../components/CaseStudiesContent';

export const Customers = () => {
    return (
        <Container fluid className='p-0'>
            <div className='ropeimg'>
                <div className="justify-content-md-center text-center subtextbox">
                    <h2 className="fw-semibold mt-2 mb-3">
                        Reliability Tested
                    </h2>
                    <h5 className="subtitlecolor">
                        Proven in the market.<br /> Validated by industry leaders.
                    </h5>
                </div>
            </div>
            <MidTitle text="Over the years, openDoctor has provided unique solutions to health systems and practices of various sizes and complexity. Our robust set of features are flexible enough to solve the most complex problems." />
            <CustomersLogo />
            <CaseStudiesContent />
        </Container>
    );
}
export default Customers;