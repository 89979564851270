import React from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import PageBanner from '../components/PageBanner';
import NewsUpdatesDetailPage from '../components/NewsUpdatesDetailPage';
import NewsUpdatesBackButton from '../components/NewsUpdatesBackButton';

const NewsUpdatesNews1 = () => {
    const newsContent = {
        title: 'Patient Portal Adoption Tops 90% Patient Registration Lags',
        paragraphs: [
            <>
                June 19, 2019. Due to the modern ability for people to have information directly at their fingertips, we expect quick and easy access to everything. Patient data and medical records are no stranger to this type of technology. In 2017 around 90% of hospitals have installed a patient portal that allows you to book appointments, have access to medical records and lab results. Despite these innovations, data has shown that less than a quarter of patients actually use their portals.
            </>,
            <>
                Technology that holds information as sensitive as someone's medical history comes with hurdles. These include difficult manual logins, limited information, and unorganized data. Yet, the issue is not solely based on the capabilities of the portal, but rather the emphasis on actually using it. This may be due to the lack of urgency patients have outside of their annual check ups. One's portal is helpful at the time of a visit, but after that, it is nothing more than another set of passwords they have remembered.
            </>,
            <>
                Moving Forward, Health IT organizations are not only working on bettering the functionality of the patient portal, but also easing patients into the common use of such technologies.
            </>,
            <>
                <div className='col-md-12 text-center'>
                    <NewsUpdatesBackButton />
                    <NavLink to="https://patientengagementhit.com/news/patient-portal-adoption-tops-90-patient-registrations-lag" target="_blank"><Button className="button large orange mx-0 mx-md-3">READ THE FULL ARTICLE</Button></NavLink>
                </div>
            </>
        ],
    };

    return (
        <div>
            <PageBanner title="News and Updates" />
            <NewsUpdatesDetailPage title={newsContent.title} paragraphs={newsContent.paragraphs} />
        </div>
    )
}
export default NewsUpdatesNews1