import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import PageBanner from '../components/PageBanner';
import { NavLink } from 'react-router-dom';

const AutomationTestEngineer = () => {
    return (
        <div>
            <PageBanner title="Careers" text="Let's bring healthcare into the 21st century" />
            <Container fluid className='p-5'>  <div className='text-center pb-5'><h4 className='fw-semibold'>Automation Test Engineer - Cypress</h4></div>
                <Row className='justify-content-md-center'>
                    <Col md={8} className="border-top pt-5">
                        <p>
                            To apply to this position, <a href="https://in.indeed.com/job/automation-test-engineer-b493dd13708a54ac" target='_blank'>click here</a>
                        </p>

                        <h6 className='fw-semibold'>
                            Job Description / Responsibilities:
                        </h6>
                        <ul>
                            <li>
                                Should have at least 3-4 year's worth of experience with test automation.
                            </li>
                            <li>
                                Writing end-to-end Test Automation for frontend web apps and non-GUI applications (Backend, REST APIs, Data).
                            </li>
                            <li>
                                Experience with testing frameworks, either in Cypress, Protractor, Nightwatch.js or Playwright Good to have: Selenium, PyTest, etc.
                            </li>
                            <li>
                                Should be aware of API Testing along with the security practices.
                            </li>
                            <li>
                                Must have knowledge of JavaScript/TypeScript.
                            </li>
                            <li>
                                Check the feasibility of requirements whether they could be automated or if there are some cases that must stay as manual.
                            </li>
                            <li>
                                Responsible for understanding, designing, and writing test scenarios and test cases; updating test cases based on the requirement changes.
                            </li>
                            <li>
                                Ready to do Manual testing as per project requirement or some other constraints.
                            </li>
                            <li>
                                Experience in working in Scrum - agile methodologies, coding standards, code reviews, source management, build processes (CI/CD Pipeline).
                            </li>
                            <li>
                                Experience working with cypress will be taken as an advantage.
                            </li>
                            <li>
                                Should communicate well in English in both spoken and written form.
                            </li>
                            <li>
                                Should be able to analyze issues and make decisions.
                            </li>
                        </ul>

                        <h6 className='fw-semibold'>
                            Desired profile of the candidate:
                        </h6>
                        <ul>
                            <li>
                                Experienced in NFR testing like load & performance using Jmeter/Loadrunner/Loader.io.
                            </li>
                            <li>
                                Good understanding of automation tools, technology landscape, and OOPS concepts.
                            </li>
                            <li>
                                Ability to work independently.
                            </li>
                            <li>
                                Should be a Passionate quality engineer with development exposure for SDET role.
                            </li>
                            <li>
                                Experience in Test Driven and Behaviour Driven Automation Framework Development.
                            </li>
                            <li>
                                Responsible for bug classification & analysis using defect tracking tools such as JIRA/QA Touch/TestTrail.
                            </li>
                            <li>
                                Good exposure to Testing Life Cycle.
                            </li>
                            <li>
                                Highly motivated/self-starter with a sense of ownership, willingness to learn, and desire to succeed.
                            </li>
                            <li>
                                Excellent leadership and interpersonal skills (both verbal and written) Strong teamwork, problem-solving, and analytical skills.
                            </li>
                            <li>
                                Experience working in regulated environments and with internal systems quality policies and procedures.
                            </li>
                            <li>
                                Defining best practices and developing technical standards, design principles, best practices, and frameworks.
                            </li>
                            <li>
                                Technical curiosity and desire to innovate.
                            </li>
                            <li>
                                Communicating with stakeholders regarding testing progress and timelines by providing testing reports and metrics.
                            </li>
                            <li>
                                Responsible for communicating across the testing community and development teams and sharing knowledge via training sessions.
                            </li>
                            <li>
                                Work collaboratively with delivery teams to ensure timely project delivery.
                            </li>
                            <li>
                                Examine the existing testing processes (manual and automation both) and suggest improvements in terms of the adoption of new approaches which can improve quality and shorten test cycles.
                            </li>
                            <li>
                                Leverage existing frameworks at an enterprise level and partner with Enterprise DevOps, Automation & Tools Team to build new frameworks by integrating with the existing tools and infrastructure.
                            </li>
                            <li>
                                Ability to adapt quickly in a constantly changing environment.
                            </li>
                        </ul>

                        <h6 className='fw-semibold'>Years of experience:</h6>
                        <ul>
                            <li>
                                total work: 3 years (Required)
                            </li>
                        </ul>

                        <h6 className='fw-semibold'>Education Qualification:</h6>
                        <p>Bachelor's Degree in Computer Science/Software Engineering Bachelor's degree or higher education.</p>

                        <h6 className='fw-semibold'>Job Type:</h6>
                        <ul>
                            <li>
                                Full-time
                            </li>
                        </ul>

                        <h6 className='fw-semibold'>Salary:</h6>
                        <ul>
                            <li>
                                Up to ₹12,00,000 a year
                            </li>
                        </ul>

                        <h6 className='fw-semibold'>Benefits:</h6>
                        <ul>
                            <li>
                                Paid sick time
                            </li>
                        </ul>

                        <h6 className='fw-semibold'>Shift and Schedule:</h6>
                        <ul>
                            <li>
                                Fixed shift
                            </li>
                            <li>
                                Monday to Friday
                            </li>
                        </ul>

                        <h6 className='fw-semibold'>Functional Area:</h6>
                        <ul>
                            <li>
                                HealthCare
                            </li>
                        </ul>
                        <br />
                        <NavLink to="/careers"><Button variant="outline-primary" id="button-addon1">  Back to all Jobs </Button></NavLink>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AutomationTestEngineer