import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import FeatureBlock from './FeatureBlock';
import img1 from '../images/icons/real-time-1.png';
import img2 from '../images/icons/Flex-Calendar-1.png';
import img3 from '../images/icons/Multiple-Locations-1.png';
import img4 from '../images/icons/Appointment-1.png';
import img5 from '../images/icons/Insurance-1.png';
import img6 from '../images/icons/Paperless-1.png';
import img7 from '../images/icons/Mobile-Check-1.png';
import img8 from '../images/icons/Patient-billing-1.png';
import img9 from '../images/icons/online-reporting-1.png';
import img10 from '../images/icons/Analytics-1.png';
import img11 from '../images/icons/mobile-friendly.png';
import img12 from '../images/icons/White-label-1.png';
import img13 from '../images/icons/Easy-Integration-1.png';
import img14 from '../images/icons/mobile_app@2x.png';
import img15 from '../images/icons/orders-driven@2x.png';
import img16 from '../images/icons/referring_provider@2x.png';


function FeatureContent() {
    return (
        <Container fluid className="p-5">
            <Row>
                <Col md={3}> <FeatureBlock imgsrc={img1} title="Real-time Appointments" text="Real-time, just like booking an airline ticket. No requests. No work queues. No telephone tag. No manual intervention. Real-time! " /> </Col>
                <Col md={3}> <FeatureBlock imgsrc={img2} title="Advanced Scheduling Logic" text="No matter the appointment complexity, family medicine to radiology, our advance scheduling logic allows for a straightforward consumer experience, both repeatable and recommendable. We also stay within the current workflows of your EHR/RIS." /> </Col>
                <Col md={3}> <FeatureBlock imgsrc={img3} title="Multiple Location Support" text="No Problem! Our scheduling logic matches patients to the right exam, location or resource every time. No more guess work and scheduling confusion." /> </Col>
                <Col md={3}> <FeatureBlock imgsrc={img4} title="Appointment Reminder Engine" text="Notification system allows you to customize the content and delivery schedule for texts, emails and voice reminders." /> </Col>
            </Row>
            <Row>
                <Col md={3}> <FeatureBlock imgsrc={img5} title="Insurance Eligibility Check" text="Eligibility check in real-time before the patient schedules the appointment." /> </Col>
                <Col md={3}> <FeatureBlock imgsrc={img6} title="Paperless Forms & Registration" text="Customizable smart forms that remember your patients so each visit becomes more consumer friendly. Easy to use, nothing to print and sent to you as you need them." /> </Col>
                <Col md={3}> <FeatureBlock imgsrc={img7} title="Mobile Check-in" text="Patients can check-in to appointments, register, and complete forms all from their own or supplied mobile device." /> </Col>
                <Col md={3}> <FeatureBlock imgsrc={img8} title="Online Payments" text="Patients can seamlessly pay outstanding balances and co-pays within the scheduling workflow for enhanced revenue cycle management." /> </Col>
            </Row>
            <Row>
                <Col md={3}> <FeatureBlock imgsrc={img9} title="Post-Appointment Reports" text="Patients and referring providers can receive post-appointment, automated reports." /> </Col>
                <Col md={3}> <FeatureBlock imgsrc={img10} title="Analytics & Workflow Tools" text="Track your analytics in real-time to learn more about how your patients are scheduling online." /> </Col>
                <Col md={3}> <FeatureBlock imgsrc={img11} title="Mobile-Friendly" text="openDoctor's features are both mobile friendly and designed for use on all mobile devices" /> </Col>
                <Col md={3}> <FeatureBlock imgsrc={img12} title="White-labeled" text="Build your brand by allowing patients to spend more time on your website, not your vendors." /> </Col>
            </Row>
            <Row>
                <Col md={3}> <FeatureBlock imgsrc={img13} title="Easy Integration" text="We have advanced experience with interfaced via HL7 and APIs for multiple systems successfully." /> </Col>
                <Col md={3}> <FeatureBlock imgsrc={img14} title="Mobile App" text="We can provide you with your own white label app, allowing consumers easy access to managing their healthcare appointments." /> </Col>
                <Col md={3}> <FeatureBlock imgsrc={img15} title="Orders-Driven Workflows" text="Expedite the scheduling process by using the order to trigger messages to patients to schedule online, in real-time. " /> </Col>
                <Col md={3}> <FeatureBlock imgsrc={img16} title="Referring Provider/Call Center Scheduling" text="openDoctor isn't just for patients. Expand your reach through our referring provider portal and reduce scheduling errors for call center staff. " /> </Col>
            </Row>
        </Container>
    );
}
export default FeatureContent