
import React from 'react'
import { Row, Col } from 'react-bootstrap'

const FaqBlock = props => {
    return (
        <Row>
            <Col>
                <h6 className='fw-semibold mb-3'>Q: {props.title}</h6>
                <p><strong>A:</strong> {props.text}</p>
                <hr className='my-4' />
            </Col>
        </Row>
    )
}

export default FaqBlock