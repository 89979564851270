import React from 'react';
import NewsUpdatesContainer from '../components/NewsUpdatesContainer';

const NewsUpdates = () => {
    return (
        <div>
            <NewsUpdatesContainer />
        </div>
    )
}
export default NewsUpdates