import React from 'react';
import PageBanner from '../components/PageBanner';
import NewsUpdatesDetailPage from '../components/NewsUpdatesDetailPage';
import NewsUpdatesBackButton from '../components/NewsUpdatesBackButton';

const NewsUpdatesContent10 = () => {
    const newsContent = {
        title: 'MedCurrent Partners with openDoctor to Enable Seamless Radiology Clinical Decision Support for Ordering Providers',
        paragraphs: [
            <>
                TORONTO (PRWEB)&nbsp;NOVEMBER 26, 2019 MedCurrent Corporation, a global leader in radiology Clinical Decision Support (CDS) solutions, today announced a partnership with openDoctor, a leader in patient scheduling automation and referring provider engagement technology. As a result, MedCurrent's OrderWise® CDS solution will be integrated into openDoctor's physician engagement platform to give ordering providers a seamless way to meet the Centers for Medicare &amp; Medicaid Services (CMS) Appropriate Use Criteria (AUC) mandate for advanced imaging set to begin in January 2020.
            </>,
            <>
                As a qualified Clinical Decision Support Mechanism (CDSM) by CMS, MedCurrent's OrderWise® CDS platform guides clinicians through clinical decision pathways to help them order the right diagnostic imaging tests for their patients at the point of care. OrderWise® incorporates diagnostic imaging guidelines from Intermountain Healthcare (IH), the National Comprehensive Cancer Network (NCCN) and the American College of Cardiology (ACC) Foundation—all CMS qualified Provider-Led Entities (qPLEs).
            </>,
            <>
                “This partnership means that clinicians who use the openDoctor platform to place advanced imaging orders will now have access to a CMS-qualified Clinical Decision Support Mechanism directly in their radiology ordering workflow,” said John Adziovsky, CEO of MedCurrent. “We are very excited to be working with openDoctor to enable physicians to meet regulatory requirements and provide their patients with more appropriate, evidence-based care with seamless end-user experience.”
            </>,
            <>
                Through the integration, ordering physicians will be able to access OrderWise® directly within openDoctor's order entry module. Once the physician identifies a patient requiring advanced imaging tests, he/she will be able to easily launch OrderWise® with a single click. OrderWise® will obtain inputs from the physician and provide evidence-based recommendations on ordering appropriateness. The openDoctor platform will subsequently send the resulting order, with evidence of AUC consultation, to any third-party system in order to complete the end-to-end workflow.
            </>,
            <>
                “We partnered with Medcurrent to provide our clients with not only a verified tool but a user friendly Clinical Decision Support Mechanism that meets the AUC mandate,” said Joe Marino, CEO of openDoctor. “Our partnership with Medcurrent strengthens our complete solution offering to contain all the digital tools necessary to automate and accelerate the ordering to scheduling journey for referring providers, imaging centers and patients.”
            </>,
            <>
                <div className='col-md-12 text-center'>
                    <NewsUpdatesBackButton />
                </div>
            </>
        ],
    };

    return (
        <div>
            <PageBanner title="News and Updates" />
            <NewsUpdatesDetailPage title={newsContent.title} paragraphs={newsContent.paragraphs} />
        </div>
    )
}
export default NewsUpdatesContent10