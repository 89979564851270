import React from 'react';
import PageBanner from '../components/PageBanner';
import NewsUpdatesDetailPage from '../components/NewsUpdatesDetailPage';
import NewsUpdatesBackButton from '../components/NewsUpdatesBackButton';
import myVideo from "../video/openDr_Webinar_May_11.mp4";

const NewsUpdatesBlog2 = () => {
    const newsContent = {
        title: 'openDoctor Hosts Online Symposium Focused on Consumerism in Radiology',
        paragraphs: [
            <>
                On May 11th, openDoctor hosted another webinar in their ongoing series. Joined by Dr. Murray Becker, President of University Radiology, the webinar focused on Consumer Engagement in Radiology: Keys to an Optimal Outpatient Experience.
            </>,
            <>
                <video controls style={{ width: '100%' }}>
                    <source src={myVideo} type="video/mp4" wid />
                </video>
            </>,
            <>
                <div className='col-md-12 text-center'>
                    <NewsUpdatesBackButton />
                </div>
            </>
        ],
    };

    return (
        <div>
            <PageBanner title="News and Updates" />
            <NewsUpdatesDetailPage title={newsContent.title} paragraphs={newsContent.paragraphs} />
        </div>
    )
}
export default NewsUpdatesBlog2