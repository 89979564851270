import React from 'react';
import { NavLink } from 'react-router-dom';
import PageBanner from '../components/PageBanner';
import NewsUpdatesDetailPage from '../components/NewsUpdatesDetailPage';
import NewsUpdatesBackButton from '../components/NewsUpdatesBackButton';

const NewsUpdatesContent5 = () => {
    const newsContent = {
        title: 'openDoctor adds Virtual Registration Client',
        paragraphs: [
            <>
                Tower Radiology is proud to announce a new Virtual Registration process for patients who schedule appointments at one of the multiple locations throughout the Tampa Bay area.
            </>,
            <>
                Virtual Registration is a big step forward in Tower's effort to decrease contact with staff to adhere to social distancing recommendations and to streamline the completion of necessary paperwork. The Virtual Registration Process will begin once a patient's appointment is scheduled. The patient will receive a confirmation text message and email with a link to start the Virtual Registration process. The patient will be able to complete forms on their phone or computer and take a photo of their ID as well as exam prescription. Patients will receive a reminder text message and instructions within ninety minutes of their appointment. Once the radiology exam is completed, the patient can leave with no checkout necessary.
            </>,
            <>
                Virtual Registration presents many efficiencies, decrease contact with staff, adhere to social distancing recommendations, shorten lobby wait times, and elevate the patient experience. The next Tower virtual feature to be rolled out at a later date is Virtual Scheduling. This new feature will allow Tower patients the ability to schedule their radiology exam on-line at their leisure.
            </>,
            <>
                About Tower Radiology Centers <br />
                Established in 1992, Tower Radiology provides quality radiology services in a comfortable, convenient, outpatient setting throughout Hillsborough, East / West Pasco and East Pinellas Counties. Accredited by the American College of Radiology, Tower offers specialized services such as 3D mammography, high field wide-bore MRI, weight-bearing open MRI, extremity open MRI, cardiac MRI, CT low-dose lung screening and PET/CT. Tower's 65 plus board-certified radiologists hold certifications in 11 radiology subspecialties and participate in the academic missions of research and teaching through serving as faculty for USF College of Medicine, Department of Radiology. For more information, go to <NavLink to="http://www.towerradiologycenters.com/" target="_blank">http://www.TowerRadiologyCenters.com</NavLink>.
            </>,
            <>
                About Tampa General Hospital <br />
                Tampa General Hospital, a 1010-bed non-profit academic medical center, delivers world-class care as the region's only center for Level l trauma and comprehensive burn care. It is one of the nation's busiest adult solid organ transplant centers and is the primary teaching hospital for the USF Health Morsani College of Medicine. Advancing medicine through innovation, Tampa General houses a nationally accredited comprehensive stroke center, an 82-bed Level IV neonatal intensive care unit, and a state-certified spinal cord and brain injury rehabilitation center. For more information, go to <NavLink to="http://www.tgh.org/" target="_blank">http://www.tgh.org</NavLink>.
            </>,
            <>
                <div className='col-md-12 text-center'>
                    <NewsUpdatesBackButton />
                </div>
            </>
        ],
    };

    return (
        <div>
            <PageBanner title="News and Updates" />
            <NewsUpdatesDetailPage title={newsContent.title} paragraphs={newsContent.paragraphs} />
        </div>
    )
}
export default NewsUpdatesContent5