import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import IntegrationBlock from './IntegrationBlock';
import img1 from '../images/logos/MergeRIS.png';
import img2 from '../images/logos/epic_logo.png';
import img3 from '../images/logos/greenway.png';
import img4 from '../images/logos/zotec.png';
import img5 from '../images/logos/mckesson.png';
import img6 from '../images/logos/nextgen.png';
import img7 from '../images/logos/ramsoft.png';
import img8 from '../images/logos/FujiLogo.gif';
import img9 from '../images/logos/ge-health.png';
import img10 from '../images/logos/ADSC_logo_large.png';
import img11 from '../images/logos/medinformatix.png';
import img12 from '../images/logos/candelis.png';
import img13 from '../images/logos/penradlogo.png';
import img14 from '../images/logos/intelerad.png';
import img15 from '../images/logos/care360.png';
import img16 from '../images/logos/pf-logo.png';
import img17 from '../images/logos/cerner-logo.png';
import img18 from '../images/logos/emds.png';
import img19 from '../images/logos/siemens-logo-en-2x.png';
import img20 from '../images/logos/eclinicalworks.png';
import img21 from '../images/logos/meditech-logo.png';
import img22 from '../images/logos/allscripts-logo.png';
import img23 from '../images/logos/kareo.png';
import img24 from '../images/logos/ViztekLogo.jpg';
import img25 from '../images/logos/Konica_Minolta.jpg';

export const IntegrationContent = () => {
    return (
        <>
            <div className="blue-bg-light p-5">
                <h4 className="mb-5 text-center fw-semibold">Who we've integrated with</h4>
                <Container fluid className="integratedlogo">
                    <Row>
                        <IntegrationBlock imgsrc={img1} text="Merge *An IBM Company" />
                        <IntegrationBlock imgsrc={img2} text="Epic: Cadence and Radiant" />
                        <IntegrationBlock imgsrc={img3} text="Greenway Health" />
                        <IntegrationBlock imgsrc={img4} text="Zotec Partners" />
                        <IntegrationBlock imgsrc={img5} text="McKesson" />
                        <IntegrationBlock imgsrc={img6} text="NextGen Healthcare" />
                        <IntegrationBlock imgsrc={img7} text="RamSoft" />
                        <IntegrationBlock imgsrc={img8} text="FujiFilm" />
                        <IntegrationBlock imgsrc={img9} text="GE Healthcare" />
                        <IntegrationBlock imgsrc={img10} text="Advanced Data Systems" />
                        <IntegrationBlock imgsrc={img11} text="Medinformatix" />
                        <IntegrationBlock imgsrc={img12} text="Candelis" />
                        <IntegrationBlock imgsrc={img13} text="PenRad" />
                        <IntegrationBlock imgsrc={img14} text="Intelerad" />
                        <IntegrationBlock imgsrc={img25} text="Konica Minolta" />
                    </Row>
                </Container>
            </div>
            <div className="p-5">
                <h4 className="mb-5 text-center fw-semibold">It's also compatible with</h4>
                <Container fluid className="integratedlogo">
                    <Row>
                        <IntegrationBlock imgsrc={img15} text="Care 360" />
                        <IntegrationBlock imgsrc={img16} text="Practice Fusion" />
                        <IntegrationBlock imgsrc={img17} text="Cerner" />
                        <IntegrationBlock imgsrc={img18} text="eMDs" />
                        <IntegrationBlock imgsrc={img19} text="Siemens" />
                        <IntegrationBlock imgsrc={img20} text="eClinicalWorks" />
                        <IntegrationBlock imgsrc={img21} text="Meditech" />
                        <IntegrationBlock imgsrc={img22} text="Allscripts" />
                        <IntegrationBlock imgsrc={img23} text="Kareo" />
                        <IntegrationBlock imgsrc={img24} text="Viztek - Konica Minolta" />
                    </Row>
                </Container>
            </div>
        </>
    );
}
export default IntegrationContent