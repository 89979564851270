import React from 'react';
import { Container } from 'react-bootstrap';
import FeatureContent from './components/FeatureContent';

export const Features = () => {
    return (
        <Container fluid className='p-0'>
            <div className='featuresimg'>
                <div className="justify-content-md-center text-center subtextbox">
                    <h2 className="fw-semibold mt-2 mb-3">
                        Feature List
                    </h2>
                    <h5 className="subtitlecolor">
                        Everything you need for scheduling automation and <br /> patient engagement.
                    </h5>
                </div>
            </div>
            <FeatureContent />

        </Container>
    )
}
export default Features;
