import React from 'react';
import PageBanner from '../components/PageBanner';
import NewsUpdatesDetailPage from '../components/NewsUpdatesDetailPage';
import NewsUpdatesBackButton from '../components/NewsUpdatesBackButton';

const NewsUpdatesContent8 = () => {
    const newsContent = {
        title: 'SIIM 2020 to Feature openDoctor Production Improvement by Patient Online Appointment Scheduling Abstract',
        paragraphs: [
            <>
                Alberto F. Goldszal, PhD, University Radiology Group, PC; Lisa Martinez; Avanthi Hulugalle; Vriti Bahirwani;<br /> Murray D. Becker, MD, PhD<br />
            </>,
            <>
                <p className='mb-2 fw-semibold'>Background/Problem Being Solved</p>
                We describe the implementation/adoption of an online patient scheduler, patient online appointment scheduling for radiology. Radiology scheduling is complex because the patient must input the prescribed exam, answer screening questions so that the offered time slots/locations have the proper resources (equipment, staff, and supplies), identify referring physician and verify insurance. The app must be easy to navigate and include all common exams. The system must account for resource availability that varies by date and time across multiple sites.<br /><br />

                <p className='mb-2 fw-semibold'>Interventions</p>
                The web-based app uses PCs, mobile phones, and tablets to interface real time via HL7 Scheduling Information Unsolicited (SIU) [1] with our EMR. This standards-based solution is EMR agnostic and supports new, rescheduled and cancelled appointments. Patients browse available time slots across multiple sites. To the EMR, the online application is<br />indistinguishable from a live scheduler who queries available appointments in real-time. Upon transaction completion, the appointment is booked into the EMR.<br /><br />

                <p className='mb-2 fw-semibold'>Barriers/Challenges</p>
                Implementation time/expense/transition period are required to move to a mixed environment of live telephone-based schedulers and online app scheduling. To lessen complexity, specialty exams were excluded: interventional, dental scans, fluoroscopy, and nuclear/PET exams. Adoption rates depend on patient awareness of the app and are reduced by patient distrust of data management, computer unfamiliarity, and preference for verbal communication. A challenge to increased adoption is optimizing ease of use concerning logon, screening questions, choosing referring provider and verifying insurance. Overly complex workflows lead users to abandon the process before completion. Google Analytics [2] defined abandonment at initial implementation: 42% abandoned at the landing page; 38% of the remaining users abandoned during login, and 41% remaining users abandoned when selecting referring provider/verifying<br />insurance. Surprisingly, abandonment was only 15% during screening questions. Initially, less than 1 in 5 (18%) completed the transaction.<br /> <br />

                <p className='mb-2 fw-semibold'>Outcome</p>
                Analytics data was used to streamlined the landing page; improve login ease and security; and improve navigation. Notably, analytics showed only minimal latency between web-based requests and EMR response.<br />Figure 1 illustrates adoption rates over a 2-year period. Initially, app usage grew modestly. Usage climbed more dramatically after the application enhancements described above and with a marketing campaign.<br />Observed use patterns: 62% used PCs, 29% mobile phones and 9% tablets (Figure 2). Ratio of 4:1 female:male users. 50% of all appointments were scheduled “off-hours” (5:00 PM to 8:00 AM). Modalities scheduled: 48% mammogram, 25% ultrasound, 14% MR, 7% DXA and 4% CT (Figure 3).<br /> <br />

                <p className='mb-2 fw-semibold'>Conclusion/Statement of Impact</p>
                Online scheduling became the preferred channel for &gt; 10% of our patients. Surveys showed it is more convenient and it delivers a better patient experience. The app is financially viable: our 10% utilization translates to about 5,000 online appointments/month, which equals the output of 6.0 live schedulers.<br /><br />

                <p className='mb-2 fw-semibold'>Lessons Learned</p>
                Online scheduling opened an important channel of communication with patients; improved access and enhanced the patient experience. Implementation required attention to ease of use. The solution has a strong ROI making it low risk but high impact.<br />
            </>,
            <>
                <div className='col-md-12 text-center'>
                    <NewsUpdatesBackButton />
                </div>
            </>
        ],
    };

    return (
        <div>
            <PageBanner title="News and Updates" />
            <NewsUpdatesDetailPage title={newsContent.title} paragraphs={newsContent.paragraphs} />
        </div>
    )
}
export default NewsUpdatesContent8