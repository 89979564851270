import React from 'react';
import { NavLink } from 'react-router-dom';
import PageBanner from '../components/PageBanner';
import NewsUpdatesDetailPage from '../components/NewsUpdatesDetailPage';
import NewsUpdatesBackButton from '../components/NewsUpdatesBackButton';

const NewsUpdatesContent2 = () => {
    const newsContent = {
        title: 'Philips and openDoctor partner to deliver unprecedented digital front door experience in radiology',
        paragraphs: [
            <>
                <strong>March 9th, 2021</strong>
            </>,
            <>
                <strong>Amsterdam, the Netherlands and New York, USA</strong>&nbsp;-&nbsp;<NavLink to="http://www.philips.com/newscenter" target="_blank" rel="noreferrer noopener">Royal Philips</NavLink> (NYSE: PHG, AEX: PHIA), a global leader in health technology, and US-based <NavLink to="https://opendr.com/" rel="noreferrer noopener">openDoctor</NavLink> today announced a partnership to deliver an integrated radiology patient engagement platform that leverages openDoctor's real-time online appointment scheduling services as part of Philips' newly launched <NavLink to="https://www.usa.philips.com/a-w/about/news/archive/standard/news/press/2020/20201129-philips-patient-management-solution-keeps-patients-safe-and-personalizes-care-during-covid-19-at-leading-u-s-medical-centers.html" target="_blank" rel="noreferrer noopener">Patient Management Solution</NavLink>.
            </>,
            <>
                With the combined offering, Philips Patient Management Solution and openDoctor enable a transformation in patient radiology scheduling, contactless registration and automated communications and education, all from the convenience of any connected mobile device. The easy-to-use scheduling platform allows patients to select appointment slots that fit their busy schedules, while optimizing staff and location availability at the same time. Patients have the ability to complete intake questionnaires in advance and take advantage of last-minute reminders and wayfinding, relieving hospital staff of burdensome manual outreach tasks, and giving radiology staff more time to personalize care to individual patients.
            </>,
            <>
                Philips Patient Management Solution has already proved invaluable for healthcare institutions across a multitude of service lines. It has helped partners improve referral conversion efficiency by 30% [1], reduce no-shows by up to 45% [2], and improve patients' knowledge of their medical conditions [3]. During the COVID-19 pandemic, the solution has been instrumental in screening patients for COVID-19 symptoms and helping practices institute contactless check-in processes to keep patients and staff as safe as possible during the restart of elective procedures.
            </>,
            <>
                “With patients becoming more active in their healthcare than ever before, openDoctor has built an impressive suite of access-to-care applications for radiology departments and imaging centers to help further enhance our Patient Management Solution, enabling us to bring fully integrated solutions to our customers and patients alike,” said <NavLink to="https://www.philips.com/a-w/about/executive-committee/kees-wesdorp.html#triggername=close_tab" target="_blank" rel="noreferrer noopener">Kees Wesdorp</NavLink>, Chief Business Leader of Precision Diagnosis at Philips. “Better prepared staff and patients can result in a higher proportion of first-time-right scans and subsequent diagnoses, leading to better patient outcomes and improved departmental efficiencies.”
            </>,
            <>
                “We are excited to partner with Philips in their commitment to redefine and revolutionize the radiology patient journey,” said Joe Marino, CEO of openDoctor. “For years, health systems have been impeded by archaic, manual processes such as phone calls and paper-based systems that plague administration departments. Now more than ever, digital transformation and automation have been thrust forward to meet the needs of operating in the COVID environment. Our combined solution will provide a comprehensive patient engagement platform that meshes together the needs of patients and the needs of health systems in a seamless workflow.”
            </>,
            <>
                The Philips Patient Management Solution is an integral component of Philips <NavLink to="https://www.philips.com/a-w/about/news/archive/standard/news/press/2020/20201124-philips-debuts-ai-enabled-automated-radiology-workflow-suite-at-rsna-2020.html" target="_blank" rel="noreferrer noopener">Radiology Workflow Suite</NavLink> designed to optimize radiology workflows to help improve patient and staff experience while enhancing clinical efficiency and operational excellence. The cloud-based solution enables modern communication between care providers and patients and serves as a digital liaison to prepare patients for their appointments, help them show up on time, and continue to follow care plan instructions long after their visit or procedure. The solution interoperates with the EMR and can send automated messages through SMS, e-mail, voice, and paper mailings, complementing existing IT and clinical workflows.
            </>,
            <>
                Philips is committed to integrating solutions from innovative partners into its open and interoperable platform. Patient management engagement is a key area for partner integration, building on Philips' previous collaborations with leading organizations integrating data, AI, and analytics solutions to support Philips growing ecosystem approach to benefit customers and the patients they serve.
            </>,
            <>
                For more information, visit the <NavLink to="https://www.usa.philips.com/healthcare/resources/landing/medumo" target="_blank" rel="noreferrer noopener">Philips Patient Management Solution site</NavLink>.
            </>,
            <>
                [1] Feng A, Elias J, Hart K, Roberts P, Laskowski K. Automated Patient Navigation Platform Increases Referral Conversion for Surgical Consultations. Paper presented at: Connected Health Conference 2018; Boston, MA.<br /><br />
                [2] Solonowicz O, Stier M, Kim K, Kupfer S, Tapper E, Sengupta N. Digital Navigation Improves No-Show Rates and Bowel Preparation Quality for Patients Undergoing Colonoscopy: A Randomized Controlled Quality Improvement Study. J Clin Gastroenterol. 2021 Jan 20. doi: 10.1097/MCG.0000000000001497. Epub ahead of print. PMID: 33471486.
                <br /><br />
                [3] Ma Y, Zeiger J, McKee S, et al. Double-blinded randomized controlled trial to evaluate a multimedia surgical care tour in improving patient satisfaction and knowledge after functional endoscopic sinus surgery. International forum of allergy &amp; rhinology. 2019;9(3):286-291.
            </>,
            <>
                Results from case studies are not predictive of results in other cases. Results in other cases may vary.
            </>,
            <>
                <div className='col-md-12 text-center'>
                    <NewsUpdatesBackButton />
                </div>
            </>
        ],
    };

    return (
        <div>
            <PageBanner title="News and Updates" />
            <NewsUpdatesDetailPage title={newsContent.title} paragraphs={newsContent.paragraphs} />
        </div>
    )
}
export default NewsUpdatesContent2