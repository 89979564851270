import React from "react";
import { Col } from "react-bootstrap";


export const IntegrationBlock = props => {
    return (
        <>
            <Col lg={3} md={4} sm={6}>
                <div className="logobox d-flex align-items-center">
                    <img src={props.imgsrc} />
                    <h3>{props.text}</h3>
                </div>
            </Col>
        </>
    );
}
export default IntegrationBlock