import React from 'react';
import PageBanner from '../components/PageBanner';
import FaqContent from '../components/FaqContent';

const Faq = () => {
    return (
        <div>
            <PageBanner title="Frequently Asked Questions" />
            <FaqContent />
        </div>
    )
}

export default Faq