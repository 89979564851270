import React from 'react'

const CaseStudiesBlock = props => {
    return (
        <div className="bg-white p-4 mt-5">
            <h6><strong>Challenge:</strong> {props.title}</h6>
            <div className='mt-3'><strong>Solution:</strong> {props.text}</div>
        </div>
    )
}

export default CaseStudiesBlock