import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import PageBanner from '../components/PageBanner';
import { NavLink } from 'react-router-dom';

const InterfaceDeveloperIntern = () => {
    return (
        <div>
            <PageBanner title="Careers" text="Let's bring healthcare into the 21st century" />
            <Container fluid className='p-5'>  <div className='text-center pb-5'><h4 className='fw-semibold'>Interface Developer Intern- HL7</h4></div>
                <Row className='justify-content-md-center'>
                    <Col md={8} className="border-top pt-5">
                        <p>
                            To apply to this position, <a href="https://in.indeed.com/cmp/Opendr-Business-Services-Pvt.-Ltd.?from=mobviewjob&tk=1griuh7fpkj8k800&fromjk=6dd92b91cd4f256a&attributionid=mobvjcmp">click here</a>
                        </p>
                        <p>
                            Our company is growing rapidly and is searching for freshers for the position of Interface
                            developer intern. If you are looking for an exciting place to work, please take a look at the list of qualifications below.
                        </p>
                        <br />
                        <h6 className='fw-semibold'>
                            Responsibilities for Interface developer intern:
                        </h6>
                        <ul>
                            <li>
                                Coding/debugging, testing and documentation of software components for Web UI and
                                backend</li>
                            <li> Support production release of system components and any post-production issue
                                resolution</li>
                            <li> Keeps up on industry trends and current web/mobile technology standards</li>
                            <li> Provide overall administrative and project support to maintain &amp; monitor Development
                                and Integration environments</li>
                            <li> Work in an agile environment - participate in sprint planning, retrospective and sprint
                                demos </li>
                            <li> Assist with migration efforts in larger projects </li>
                        </ul>
                        <p>Good communication skills.</p>
                        <p>Ability to think out of the box and create scenarios to break the system.</p>

                        <br />
                        <h6 className='fw-semibold'>Qualifications for Interface developer intern:</h6>
                        <ul>
                            <li> Graduate </li>
                            <li> SDLC Tools such as Git</li>
                            <li> Database experience RDBMS and NOSQL</li>
                            <li> More than 2 programing language PHP, NodeJs, Typescript, Java, Python, Rust, R, Perl, Go, Ruby on Rails and C# </li>
                            <li>HTML and javascript knowledge is mandatory</li>
                        </ul>

                        <br />
                        <p>
                            <b>Job Type:</b>  Full-time, Fresher, Internship, Contractual / Temporary, Volunteer<br /><br />
                            <b>Contract length:</b> 6 months
                            <br /><br />

                            <b>Salary:</b> ₹8,000.00 - ₹10,000.00 per month
                        </p>
                        <br />
                        <h6 className='fw-semibold'>Benefits:</h6>
                        <ul>

                            <li>
                                Paid time off
                            </li>
                        </ul>
                        <br />
                        <h6 className='fw-semibold'>Schedule:</h6>
                        <ul>
                            <li>
                                Day shift
                            </li>
                            <li>
                                Flexible shift
                            </li>
                            <li>
                                Monday to Friday
                            </li>
                        </ul>

                        <br />
                        <NavLink to="/careers"><Button variant="outline-primary" id="button-addon1">  Back to all Jobs </Button></NavLink>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default InterfaceDeveloperIntern