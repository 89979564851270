import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import PageBanner from '../components/PageBanner';
import { NavLink } from 'react-router-dom';

const QualityAssuranceTester = () => {
    return (
        <div>
            <PageBanner title="Careers" text="Let's bring healthcare into the 21st century" />
            <Container fluid className='p-5'>
                <div className='text-center pb-5'><h4 className='fw-semibold'>Senior PHP Developer</h4></div>
                <Row className='justify-content-md-center'>
                    <Col md={8} className="border-top pt-5">
                        <p>
                            To apply to this position, <a href="https://in.indeed.com/cmp/Opendr-Business-Services-Pvt.-Ltd.?from=mobviewjob&tk=1griuh7fpkj8k800&fromjk=6dd92b91cd4f256a&attributionid=mobvjcmp">click here</a>
                        </p>
                        <p>
                            We are looking for a seasoned full-stack engineer to build out and manage our web application. Successful candidates will have a minimum of three years' recent experience with all of the skills listed below. Our project entails implementing pre-approved UI, constructing API, connecting the two, and working with Cloud Engineers on infrastructure, Monitoring, optimization and scaling. This includes all associated areas such as database and architecture design.
                        </p>
                        <br />
                        <h6 className='fw-semibold'>
                            Position Responsibilities:
                        </h6>
                        <ul>
                            <li>
                                Design and implementation of the overall web architecture.
                            </li>
                            <li>
                                "Pixel-perfect" implementation of our approved user interface.
                            </li>
                            <li>
                                Design and deployment of our database.
                            </li>
                            <li>
                                Ensuring the entire stack is designed and built for speed and scalability.
                            </li>
                            <li>
                                Design and construction of our REST API.
                            </li>
                            <li>
                                Integrating our front-end UI with the constructed API.
                            </li>
                            <li>
                                Design and implementation of continuous integration and deployment.
                            </li>
                            <li>
                                Following cyber security principals while building, deploying and running the application.
                            </li>
                            <li>
                                Working with the Support team.
                            </li>
                        </ul>
                        <br />
                        <h6 className='fw-semibold'>Skills:</h6>
                        <ul>
                            <li>
                                At least three years' experience with: o Writing HTML, CSS, and JavaScript. Being up on latest practices is a must, e.g., HTML5, CSS3, and ECMAScript 6 (minimum). A relevant back-end programming language PHP (Must), TypeScript, Python or JavaScript Database design and management, including being up on the latest practices and associated versions. Server management and deployment for the relevant environment
                            </li>
                            <li>
                                Familiarity with a relevant and globally supported framework—both front-end and back-end, if necessary—e.g., React, Vue, Zend , Laravel, Symfony or Flask.
                            </li>
                            <li>
                                Ideally, familiarity with CSS pre-processors, bundlers, and associated languages/syntaxes/libraries e.g., Sass, Less, tailwind and webpack
                            </li>
                            <li>
                                Thorough understanding of user experience and possibly even product strategy
                            </li>
                            <li>
                                Experience implementing testing platforms and unit tests
                            </li>
                            <li>
                                Proficiency with GIT
                            </li>
                            <li>
                                Appreciation for clean and well documented code
                            </li>
                            <li>
                                Join a team of great technical professionals that work hard but have fun and enjoy developing state of the art product-based applications and websites.
                            </li>
                            <li>
                                Use best practice coding standards and implement each deliverable.
                            </li>
                            <li>
                                Write and manage program and Bug Fixes
                            </li>
                            <li>
                                Construct and support integrations that are easy to manage, maintain, customize and configure; ensure overall reliability, availability, and high- performance code.
                            </li>
                            <li>
                                Analyze system issues and problems and implement solutions rapidly.
                            </li>
                            <li>
                                Manage multiple tasks simultaneously varying in sizes and scope
                            </li>
                            <li>
                                Work in a fast-paced cross-functional team environment. Effectively handle time-critical issues and appropriately escalate at-risk items.
                            </li>
                        </ul>
                        <br />
                        <h6 className='fw-semibold'>Other Skills:</h6>
                        <ul>
                            <li>
                                Strong knowledge of OOPs Concepts.
                            </li>
                            <li>
                                Computer Science/Software Engineering Bachelor's degree or higher education.
                            </li>
                            <li>
                                Excellent written and spoken communication skills.
                            </li>
                            <li>
                                Knowledge of Agile
                            </li>
                        </ul>
                        <br />
                        <h6 className='fw-semibold'>Experience:</h6>
                        <p>
                            5 years <br /><br />
                            <b>Job Type:</b> Full-time<br /><br />
                            <b>Salary:</b> Up to ₹12,00,000.00 per year
                        </p>
                        <br />
                        <h6 className='fw-semibold'>Benefits:</h6>
                        <ul>
                            <li>
                                Health insurance
                            </li>
                            <li>
                                Life insurance
                            </li>
                            <li>
                                Paid time off
                            </li>
                        </ul>
                        <br />
                        <h6 className='fw-semibold'>Schedule:</h6>
                        <ul>
                            <li>
                                Day shift
                            </li>
                            <li>
                                Monday to Friday
                            </li>
                        </ul>
                        <br />
                        <h6 className='fw-semibold'>Supplemental Pay:</h6>
                        <p>Performance Bonus pay</p>
                        <br />
                        <h6 className='fw-semibold'>Ability to commute/relocate:</h6>
                        <p>
                            Andheri, Mumbai - 400059, Maharashtra: Reliably commute or planning to relocate before starting work (Required)
                        </p>
                        <br />
                        <NavLink to="/careers"><Button variant="outline-primary" id="button-addon1">  Back to all Jobs </Button></NavLink>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default QualityAssuranceTester