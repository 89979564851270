import React from 'react';
import PageBanner from '../components/PageBanner';
import RoiCalculator from '../components/RoiCalculator';

export const Roi = () => {
    return (
        <div className="App">
            <PageBanner title="ROI Calculator" text="Calculate your potential savings below" />
            <RoiCalculator />
        </div>
    );
}
export default Roi;