import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const PageBanner = props => {
    return (
        <div className="pagebanner p-5 p-lg-5 p-sm-4 ">
            <Container className="my-2">
                <Row className="justify-content-md-center text-center">
                    <Col md={10}>
                        <h2 className="fw-semibold mt-4 mb-3">
                            {props.title}
                        </h2>
                        <h5 className="subtitlecolor">
                            {props.text}
                        </h5>
                    </Col>
                </Row>
            </Container>
        </div>
    );

}

export default PageBanner;