import React from 'react';
import { Row, Col } from 'react-bootstrap';

const TeamBlock = props => {
    return (
        <Col lg={6}>
            <div className='border-top py-4 mb-4'>
                <Row>
                    <Col md={5} className="text-center mb-4">
                        <img src={props.imgsrc} width="170" alt="Team" />
                    </Col>
                    <Col md={7}>
                        <h5 className="fw-semibold mb-0">{props.title}</h5>
                        <h6 className="mb-3"><small>{props.subtitle}</small></h6>
                        <div>{props.text}</div>
                    </Col>
                </Row>
            </div>
        </Col>
    )
}

export default TeamBlock