import React from 'react';
import { Container } from 'react-bootstrap';
import MidTitle from '../components/MidTitle';
import IntegrationContent from '../components/IntegrationContent';

export const Integration = () => {
    return (
        <Container fluid className='p-0'>
            <div className='integrationimg'>
                <div className="justify-content-md-center text-center subtextbox">
                    <h2 className="fw-semibold mt-2 mb-3">
                        It's Integration
                    </h2>
                    <h5 className="subtitlecolor">
                        openDoctor is configurable and will work with <br />your existing EHR/RIS systems.
                    </h5>
                </div>
            </div>
            <MidTitle text="Our platform delivers a powerful solution that is real-time and easy to use, so it suits your workflow needs and aligns with your strategic requirements." />
            <IntegrationContent />
        </Container>
    )
}
export default Integration;