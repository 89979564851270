import React from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const NewsUpdatesBackButton = props => {
    return (
        <NavLink to="/news-updates"><Button className="my-4 mx-0 mx-md-3" variant="outline-primary" id="button-addon1">Back to News and Updates</Button></NavLink>
    )
}

export default NewsUpdatesBackButton