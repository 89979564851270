import React from 'react';
import PageBanner from '../components/PageBanner';
import NewsUpdatesDetailPage from '../components/NewsUpdatesDetailPage';
import NewsUpdatesBackButton from '../components/NewsUpdatesBackButton';

const NewsUpdatesContent7 = () => {
    const newsContent = {
        title: 'An Important Statement regarding COVID-19, openDoctor is here to help',
        paragraphs: [
            <>
                A statement from openDoctor CEO, Joe Marino:
            </>,
            <>
                Hello,<br /><br />
                As we're all preparing to halt the spread of COVID-19, we are in close and continuing discussions with our clients and market leaders on offering best practices for patient communications.<br /><br />
                Our clients and partners are currently experiencing:<br />
                <strong>- Staffing shortages</strong><br />
                <strong>- Increased inbound call volume</strong><br />
                <strong>- Growing no-shows</strong><br /><br />
                Our goal is to limit the exposure of the virus and assist in alleviating operational challenges. Working with our partners, openDoctor is creating digital campaigns focused on addressing patient education.<br />
            </>,
            <>
                Our recommendations:<br />
                <strong>- Website pop-up offering to reschedule appointments</strong><br />
                <strong>- Digital communication: email and text message outreach for upcoming appointments</strong><br />
                <strong>- openDr patient portal notification for existing patients of the practice</strong><br />
                <strong>- Expediting additional exams that can be self-scheduled online via patients and referring providers</strong><br /><br />
                For practices with limited digital capabilities, we are offering complimentary assistance with digital communication strategies regarding your updated COVID-19 protocols.<br /><br />
                We will continue to provide market insight with best practices. We encourage others to share additional strategies that they find successful.<br /><br />

                Thanks and stay safe,<br />
                Joe Marino<br />
                Chief Executive Officer<br />
                openDoctor
            </>,
            <>
                <div className='col-md-12 text-center'>
                    <NewsUpdatesBackButton />
                </div>
            </>
        ],
    };

    return (
        <div>
            <PageBanner title="News and Updates" />
            <NewsUpdatesDetailPage title={newsContent.title} paragraphs={newsContent.paragraphs} />
        </div>
    )
}
export default NewsUpdatesContent7