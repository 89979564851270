import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function NewsUpdatesDetailPage({ title, paragraphs }) {
    return (
        <Container fluid className='p-5 newsupdatedetail'>
            <Row className='justify-content-md-center'>
                <Col md={8}>
                    <div>
                        <div className='text-center pb-4 mb-5 border-bottom'><h4 className='fw-semibold'>{title}</h4></div>
                        {paragraphs.map((paragraph, index) => (
                            <p key={index}>{paragraph}</p>
                        ))}
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
export default NewsUpdatesDetailPage