import React from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import PageBanner from '../components/PageBanner';
import NewsUpdatesDetailPage from '../components/NewsUpdatesDetailPage';
import NewsUpdatesBackButton from '../components/NewsUpdatesBackButton';

const NewsUpdatesUpdates4 = () => {
    const newsContent = {
        title: 'What does consumerism in healthcare look like?',
        paragraphs: [
            <>
                The interaction between healthcare organizations and patients is changing. What does it look like right now?
            </>,
            <>
                <h5><strong>We have entered an era of healthcare consumerism. But, what does it look like? What could hold it back from reaching maturity anytime soon?</strong></h5>
            </>,
            <>
                These questions were addressed in a recent article on <NavLink to="http://www.cio.com/article/3002110/healthcare/healthcare-consumerism-is-here-or-is-it-really.html" target="_blank" rel="noopener noreferrer">CIO.com</NavLink>. The following points on the impact of consumerism in healthcare stood out to us:
            </>,
            <>
                <ul class="bullet">
                    <li>The patient - healthcare system interaction is changing from a one-off interaction to more of an ongoing, 24/7 communication</li>

                    <li>Healthcare companies will need to invest in digital technology to reach consumers anytime and anywhere through their devices of choice</li>

                    <li>Interactions are also becoming less transactional and more emotion-based, meaningful interactions</li>

                    <li>Patient expectations around the quality of the experience with healthcare providers are rising</li>

                    <li>With the ongoing shift to high deductible health plans, consumers are forced to shop around and make more informed choices about where and how they are going to receive healthcare and from whom</li>

                    <li>While data and analytics are being widely adopted by healthcare companies, issues such as interoperability will remain a challenge to overcome</li>
                </ul>
            </>,
            <>
                <div className='col-md-12 text-center'>
                    <NewsUpdatesBackButton />
                    <NavLink to="https://www.cio.com/article/3002110/healthcare/healthcare-consumerism-is-here-or-is-it-really.html" target="_blank"><Button className="button large orange mx-0 mx-md-3">READ THE FULL ARTICLE</Button></NavLink>
                </div>
            </>
        ],
    };

    return (
        <div>
            <PageBanner title="News and Updates" />
            <NewsUpdatesDetailPage title={newsContent.title} paragraphs={newsContent.paragraphs} />
        </div>
    )
}
export default NewsUpdatesUpdates4