import React from 'react';
import { NavLink } from 'react-router-dom';
import PageBanner from '../components/PageBanner';
import NewsUpdatesDetailPage from '../components/NewsUpdatesDetailPage';
import NewsUpdatesBackButton from '../components/NewsUpdatesBackButton';

const NewsUpdatesCaseStudy1 = () => {
    const newsContent = {
        title: 'How do high-deductible health plans affect radiology?',
        paragraphs: [
            <>
                Many providers experience serious challenges when trying to efficiently and accurately bill patients and payers.
            </>,
            <>
                The payments collection procedure in healthcare has forever been one of the more complex and difficult processes. Many providers experience serious challenges when trying to efficiently and accurately bill patients and payers.
            </>,
            <>
                In a recent article published on <NavLink to="https://www.auntminnie.com/index.aspx?sec=sup&amp;sub=imc&amp;pag=dis&amp;ItemID=117157" target="_blank" rel="noopener noreferrer">AuntMinnnie.com</NavLink>, Carin Carlson explores the current state of the industry and the payment processes, with a focus on radiology.
            </>,
            <>
                "With the advent of managed care contracting where physicians were paid directly by the insurance company, patient balance collections mostly disappeared. Today the pendulum is swinging back in the opposite direction, requiring practices to once again face the need to collect significant balances from patients."
            </>,
            <>
                Carlson continues on discussing how "radiologists and other hospital-based physicians are at a particular disadvantage"; this is mainly due to the fact that they often do not have direct contact with the patient at the time of service "which is the best time to start the collection process."
            </>,
            <>
                One of the difficulties in the process comes from the lack of shared information between parties involved. Carlson dives into this topic stating:
            </>,
            <>
                "The hospital and the radiology group have a common interest in handling this problem together. Radiologists can take the lead by setting up a process that promotes the two-way sharing of patient demographic and insurance coverage information, which is critical to the success of both parties in collecting the full amount due."
            </>,
            <>
                <div className='col-md-12 text-center'>
                    <NewsUpdatesBackButton />
                </div>
            </>
        ],
    };
    return (
        <div>
            <PageBanner title="News and Updates" />
            <NewsUpdatesDetailPage title={newsContent.title} paragraphs={newsContent.paragraphs} />
        </div>
    )
}
export default NewsUpdatesCaseStudy1