import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import FaqBlock from './FaqBlock';

const FaqContent = () => {
    return (
        <Container fluid className='p-5'>
            <Row className="justify-content-md-center">
                <Col lg={9}>
                    <FaqBlock title="What is an authentication code and why do I need one?" text="An authentication code is a one time verification that openDoctor uses to ensure that we have all the correct information about you so that we can contact you about upcoming appointments. We send the authentication code to you via text message. Once you enter that code on our webpage, your account will be active and you can complete scheduling, search for new appointments, and adjust your notification preferences." />
                    <FaqBlock title="What if I don't have a cell phone or can't receive text messages?" text="If you can't receive your authorization code in a text message, contact us here and we will promptly contact you to verify your information and activate your account." />
                    <FaqBlock title="I set up my account with the wrong phone number by accident. What should I do?" text="Not a big deal. Contact us here, and we'll give you a call to verify some basic information and activate your account. After that, you'll be able to log in and update your phone number, as well as search for open appointments." />
                    <FaqBlock title="I forgot my password." text="Click here, then enter your first name, last name, and email address. Then check your email, and you should find a link to reset your password and get you on track to online scheduling." />
                    <FaqBlock title='I tried to enroll for online scheduling and registration but received the message "this patient is already in use".' text="This means you already have an openDoctor account, so you don't need to sign up for a new account. Click here, then enter your first name, last name, and email address. Then check your email - we'll email you a link to reset your password, and you'll be scheduling an appointment in no time!" />
                    <FaqBlock title="Why am I not receiving emails from openDoctor?" text="Check your spam folder – it's possible openDoctor emails are being filtered out since you're new to the service. If you still don't see them, contact us here." />
                    <FaqBlock title="Why do I have to answer so many questions to schedule an appointment?" text="Your healthcare provider wants to know as much about you beforehand as possible, so that he or she can provide the best possible care. In some cases, that may mean a few simple questions about your current and past health. In other cases, like a complex radiology exam, it may include a lot of detailed questions to ensure both you and the clinic are prepared for your appointment." />
                    <FaqBlock title="I received a message that says I need to call the clinic. Why can't I schedule my appointment online?" text="In some special cases, you will need to talk to someone at the clinic to make sure we're scheduling the right appointment for your individual situation. In these cases, you can either call the clinic directly, or use the 'call me' button to generate a request for the clinic to call you." />
                    <FaqBlock title="How do I fill out patient forms online?" text="Prior to your scheduled appointment, you will receive an email with a link to your patient forms. Click that link, log in with the email address and password for you account, and you'll be able to save time filling out paper forms by completing them online." />
                    <FaqBlock title="What if I don't want to fill out my patient registration forms online? Will I be able to complete them later?" text="Of course! You can always fill out paper forms once you get to your physician's office if you prefer. Here at openDoctor, we prefer doing them online, when we have time, so we can get in and out as quickly as possible on the day of our appointment." />
                    <FaqBlock title="I got an email or text message from openDoctor, even though I've never used it before. What's going on?" text="Even if you've never used our service before, openDoctor lets healthcare providers provide helpful automated notifications and offer online patient forms no matter how you booked your appointment – over the phone, online, or by messenger pigeon. That way everyone saves time, and helps you remember when you have to be at the doctor." />
                    <FaqBlock title="Can I adjust the number of appointment notifications that I get?" text="When you are signed into your openDoctor profile, select the home button in the top right corner of your screen. From there, you will be able to select the notifications section, and decide whether you would like to see text message and/or email reminders 7, 3, and 1 day prior to your appointment." />
                    <FaqBlock title="I loved saving time using openDoctor and wish I could use it to schedule all my appointments! How can I get other healthcare providers to use openDoctor?" text="We think openDoctor is pretty neat, and we're glad you agree. If you had a great experience with openDoctor, please feel free to share with any friends or acquaintances (whether or not they work in healthcare), or drop us a note here. And if there is anything we could have done better, we'd love to hear that too." />
                </Col>
            </Row>
        </Container>
    )
}

export default FaqContent