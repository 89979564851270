import React from 'react';
import { NavLink } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import PageBanner from '../components/PageBanner';
import LinkMailto from '../components/LinkMailto';
import TermsPrivacyUpdatedDate from '../components/TermsPrivacyUpdatedDate';

const TermsConditions = () => {
    return (
        <div>
            <PageBanner title="openDoctor Terms of Service" />
            <Container fluid className='p-5'>
                <Row className='justify-content-md-center'>
                    <Col md={8}>
                        <p><strong>PLEASE READ THE FOLLOWING TERMS OF SERVICE RELATING TO YOUR USE OF THE openDoctor WEBSITE. THESE TERMS OF SERVICE GOVERN YOUR USE OF THE openDoctor WEBSITE AND ALL SERVICES OFFERED THROUGH THE WEBSITE.</strong></p>
                        <p>These Terms of Service ("TOS") apply to all users of the openDoctor website (the "Site"). The term "Site" as it is used in these TOS includes access via any device.</p>
                        <p>BY USING THE SITE, AND/OR BY REGISTERING WITH US, YOU SIGNIFY THAT YOU AGREE TO THESE TERMS OF SERVICE, including that you consent to the information practices disclosed in our <NavLink to="/privacy-policy">Privacy Policy</NavLink>, which is incorporated herein by reference, and that you consent to resolve in the State of New York any dispute that you may have with us, or the Site. <span className="trademark">openDoctor</span> reserves the right to update or change these TOS at any time by posting the most current version of the TOS on the Site. Your continued use of the Site after we post any changes to the TOS signifies your agreement to any such changes. If you do not agree to these TOS, you must discontinue using the Site and if applicable terminate your account with openDoctor. This Site is owned and operated by openDoctors <span className="opDocTrade">24<span>7</span> , Inc</span> , d/b/a <span className="trademark">openDoctor</span>. All references to <span className="trademark">openDoctor</span> herein shall be deemed to include openDoctors <span className="opDocTrade">24<span>7</span> , Inc</span>.</p>
                        <ol className="numbers">
                            <li><h6 className="fw-semibold">Registering as a Member</h6>
                                <p>You do not have to be an <span className="trademark">openDoctor</span> member to use this Site. Some of what's on the Site is viewable without registering with us, but to actively participate or store your information, you must register as a member and authorize the use and disclosure of your personal and health information.</p>
                                <p>When you register with <span className="trademark">openDoctor</span>, you will be asked to provide your name, birthday, email address, cell or mobile phone number and other information that we may request, all of which will become part of your Profile, and which you can add to or change from time to time. You will also be asked to select a user name and password which will serve to identify you as a Member each time you visit the Site and allow you to access your account. <span className="trademark">openDoctor</span> may, from time to time, use the information you provide to us for, among other things, the purpose of communicating with you.</p>
                                <p>You grant us and all other persons or entities involved in the operation of this website the right to transmit, monitor, retrieve, store, and use your information in connection with the operation of our website and in communication with you. We do not assume any responsibility or liability for any information you submit, or your or any other parties' use or misuse of information transmitted or received using our website's tools and services. By using our website you also agree to our <NavLink to="/privacy-policy">Privacy Policy</NavLink>. Please review our <NavLink to="/privacy-policy">Privacy Policy</NavLink> regarding the use of cookies, HIPAA, and related information.</p>
                            </li>
                            <li><h6 className="fw-semibold">Using the Services</h6>
                                <p>This Site and the services offered by <span className="trademark">openDoctor</span> through the Site (the "Services") are intended for your personal, noncommercial use in accordance with these TOS. You agree that you will not (i) copy, display or distribute any part of the Site, in any medium, without <span className="trademark">openDoctor</span> prior written consent, or (ii) alter or modify any part of the Site other than as may be reasonably necessary to use the Site for its intended purpose and as is expressly permitted by <span className="trademark">openDoctor</span> or the Site.</p>
                                <p>You are responsible for all use of the Site and for all use of your user name, password and any other information you provide to <span className="trademark">openDoctor</span> that relate to your access to the Site or your Profile ("Credentials"), including use by others to whom you have given your Credentials. You are responsible for keeping your password confidential and for notifying us if you become aware that your password has been hacked, stolen or known to others. You may notify us by <NavLink to="/contact-us">clicking here</NavLink>. You may use the Site and the Services for lawful, non-commercial purposes only. You may not use the Site in any manner that could damage, disable, overburden, or impair our servers or networks, or interfere with any other party's use and enjoyment of the Site or the Services.</p>
                                <p>You further agree that you will not use any automated devices, such as spiders, robots or data mining techniques to catalog, download, store or otherwise reproduce, store or distribute content available on the Site, or to manipulate the Site or the Services, or otherwise exceed the limited access granted to you by <span className="trademark">openDoctor</span>. You will take no action to interfere with, interrupt, destroy or limit the functionality of the Site or any computer software or hardware or telecommunications equipment. You will not distribute or transmit any content or software or other computer files that contain a computer virus or other harmful component.</p>
                            </li>
                            <li><h6 className="fw-semibold">Changing the Services</h6>
                                <p>We may from time to time add new services to the Services, substitute a new service for one of the existing Services, or discontinue or suspend one of the existing Services. Information about the new services will be included on the Site, and the users of new services will be governed by these TOS. You agree that <span className="trademark">openDoctor</span> will not be liable to you or any third party for any suspension or discontinuation of any of the Services.</p>
                            </li>
                            <li>
                                <h6 className="fw-semibold">Proprietary Rights</h6>
                                <p>The Site and all material published on the Site, including, but not limited to text, photographs, video, text, graphics, music, sounds, messages, comments, ratings, and other materials is owned by <span className="trademark">openDoctor</span> or its licensors and is protected by copyright, patents, trademarks, trade secrets and/or other proprietary rights, including under the United States copyright laws. <span className="trademark">openDoctor</span> owns a copyright in the selection, coordination, arrangement and enhancement of such content and a copyright in the Site.</p>
                                <p>All trademarks appearing on this Site ("Marks") are trademarks of their respective owners, including <span className="trademark">openDoctor</span> and its partners. Users are prohibited from using any Marks without the written permission of <span className="trademark">openDoctor</span> or such third party that may own the Marks.</p>
                                <p>Content on the Site is provided AS IS for your information and personal use only. You may not copy, publish, transmit, distribute, perform, sell, create derivative works of, or in any way exploit, any of the content, in whole or in part, without <span className="trademark">openDoctor</span> prior written consent. You may download content for your personal, non-commercial use only as provided in these TOS, provided that you keep intact all copyright and other proprietary notices. Copying or storing of content for other than personal use is expressly prohibited without prior permission from us or the copyright holder identified in the copyright notice contained in the content.</p>
                            </li>
                            <li><h6 className="fw-semibold">Age Requirements</h6>
                                <p>You must be 18 or over, or the legal age to form a binding contract in your jurisdiction if that age is greater than 18 years of age, to register with us or use the Site and the Services. If you are between the ages of 13 and 18 or the applicable legal age in your jurisdiction, you can use the Site or Services only in conjunction with, and under the supervision of, your parent or guardian who has agreed to the Terms of Service. If you are under the age of 13, you may not use the Site or Services, in compliance with the Children's Online Privacy Protection Act. If you are the parent or legal guardian of a child under the age of 18, you may use the Site or Services on behalf of such minor child. By using the Site or Services on behalf of a minor child, you represent and warrant that you are the parent or legal guardian of such child. If you do not qualify under these terms, do not use the Site or Services. Membership in the Services is void where prohibited by applicable law, and the right to access the Site is revoked in such jurisdictions. By using the Site and/or the Services, you represent and warrant that you have the right, authority, and capacity to enter into these Terms of Service and to abide by all of the terms and conditions set forth herein. The Site is administered in the U.S. and intended for U.S. users; any use outside of the U.S. is at the user's own risk and users are responsible for compliance with any local laws applicable to their use of the Services or the Site.</p>
                            </li>
                            <li>
                                <h6 className="fw-semibold">No License Granted</h6>
                                <p>Except for allowing you to use the Site for your personal use as set forth in the paragraph above, when you use the Site, you are not receiving a license or any other rights from us, including intellectual property or other proprietary rights of <span className="trademark">openDoctor</span>. YOU UNDERSTAND THAT YOU HAVE NO RIGHTS TO THE SERVICES OR ANY OTHER <span className="trademark">openDoctor</span> PROPERTY EXCEPT AS WE INDICATE IN THESE TERMS. YOUR ABILITY TO USE THE SITE MAY BE LIMITED OR TERMINATED AT ANYTIME FOR ANY REASON OR FOR NO REASON.</p>
                            </li>
                            <li>
                                <h6 className="fw-semibold">Links to Other Sites</h6>
                                <p>The Site may include links to other websites, including links provided as automated search results. Some of these sites may contain materials that are objectionable, unlawful, or inaccurate. These links are provided for your convenience only and do not mean that we endorse these sites or the products and services they provide. You acknowledge and agree that we are not responsible or liable for the content or accuracy of these other sites. Your use of the websites of other parties is at your own risk and subject to the terms and conditions of use for those sites. We do not endorse any product, service, or treatment advertised on our website.</p>
                            </li>
                            <li>
                                <h6 className="fw-semibold">Communications from <span className="trademark">openDoctor</span></h6>
                                <p>If you use our sites and services, <span className="trademark">openDoctor</span> may communicate with you via electronic message including email, text messages in accordance to our privacy policy. Message frequency varies per user. You can cancel this text message service at any time. Just reply to the text “STOP”. Message and data rates may apply. If you have any questions about your text plan or data plan, it is best to contact your wireless provider.After you send the message “STOP” to us, we will send a reply message to confirm you have been unsubscribed. After this, you will no longer receive messages. If you want to join again, you can update your profile information to enable texts.</p>
                                <ul>
                                    <li>Participating carriers: AT&amp;T, Verizon Wireless, Sprint, T-Mobile, U.S. Cellular, Boost Mobile, MetroPCS, Virgin Mobile, Alaska Communications Systems (ACS), Appalachian Wireless (EKN), Bluegrass Cellular, Cellular One of East Central, IL (ECIT), Cellular One of Northeast Pennsylvania, Cricket, Coral Wireless (Mobi PCS), COX, Cross, Element Mobile (Flat Wireless), Epic Touch (Elkhart Telephone), GCI, Golden State, Hawkeye (Chat Mobility), Hawkeye (NW Missouri), Illinois Valley Cellular, Inland Cellular, iWireless (Iowa Wireless), Keystone Wireless (Immix Wireless/PC Man), Mosaic (Consolidated or CTC Telecom), Nex-Tech Wireless, NTelos, Panhandle Communications, Pioneer, Plateau (Texas RSA 3 Ltd), Revol, RINA, Simmetry (TMP Corporation), Thumb Cellular, Union Wireless, United Wireless, Viaero Wireless, and West Central (WCC or 5 Star Wireless).</li>
                                    <li>T-Mobile is not liable for delayed or undelivered messages.</li>
                                </ul>
                                <br />
                            </li>
                            <li>
                                <h6 className="fw-semibold">Additional Terms</h6>
                                <p>Certain of the Services on the Site may have additional terms (such as policies, guidelines, and rules) that will further govern your use of that particular Service and supplement these Terms of Use. If you choose to register for or access any such Services, you will be presented with any relevant additional terms and conditions at that time. By using those Services, you agree to comply with such additional guidelines and rules.</p>
                            </li>
                            <li>
                                <h6 className="fw-semibold">No Spam</h6>
                                <p>You may not use contact information provided by our users or collaborating physicians, or harvest such information for the purpose of sending, or to facilitate the sending, of unsolicited bulk communications such as SPAM. You may not allow others to use your account to violate the terms of this section. We may terminate your membership or access to the Site immediately and take other legal action if you or anyone using your Credentials violates these provisions.</p>
                            </li>
                            <li>
                                <h6 className="fw-semibold">Authorized Use</h6>
                                <p>Any person is hereby authorized to view the information available in this Site for informational purposes only. Any product, process or technology described in this Site may be subject to intellectual property rights, which rights are reserved by <span className="trademark">openDoctor</span> and are not licensed or otherwise transferred hereunder.</p>
                            </li>
                            <li>
                                <h6 className="fw-semibold">Digital Millennium Copyright Act ("DMCA") Notice</h6>
                                <p>Materials may be made available via the Site and the Services by third parties not within our control. We are under no obligation to, and do not, scan content posted, however, we respect the copyright interests of others. It is our policy not to permit materials known by us to infringe another party's copyright to remain on the Site or the Service.</p>
                                <p>If you believe any materials on the Site or the Service infringe a copyright, you should provide us with written notice that at a minimum contains:</p>
                                <ul>
                                    <li>A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;</li>
                                    <li>Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site;</li>
                                    <li>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material;</li>
                                    <li>Information reasonably sufficient to permit us to contact the complaining party, such as an address, telephone number, and, if available, an electronic mail address at which the complaining party may be contacted;</li>
                                    <li>A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</li>
                                    <li>A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
                                </ul>
                                <p> All DMCA notices should be sent to our designated agent as follows:</p>
                                <div className="agent"><strong>Joe Marino</strong><br />
                                    <span className="trademark">openDoctor</span><br />
                                    33 Park PL<br />
                                    S110, New York, NY 10007<br />
                                    <LinkMailto label="userssupport@opendr.com" mailto="mailto:userssupport@opendr.com" />
                                    <br /><br />
                                </div>
                            </li>
                            <li>
                                <h6 className="fw-semibold">No Medical or Professional Services Advice</h6>
                                <p>The content on this Site is intended to be a general information resource in regard to the subject matter covered. <span className="trademark">openDoctor</span> does not directly or indirectly practice medicine, render medical advice, or dispense medical services via the Site, and nothing contained in this Site is intended to be instruction for medical diagnosis or treatment. No client, doctor-patient, confidentiality, advisory, fiduciary or professional relationship of any type is established or implied by our provision of our website and the services it offers. You acknowledge that neither we nor any other person is, in connection with the Site, engaged in providing consulting or other professional services or advice. The Site is NOT A REFERRAL SITE NOR AN ENDORSEMENT OF ANY PROVIDER FOUND ON IT. It is up to you to determine whether a doctor or other service provider is properly credentialed, in good standing, and is otherwise right for you. You must take full responsibility in asking questions, verifying information and suitability of the provider's credentials and competence. We will not be liable for any actions or damages that arise from your or any other person's visits or contacts with any providers that may be obtained from or through our website. We are a listing and informational website. You and other users must accept all responsibility in determining the suitability of any provider found or contacted via our website. <strong>You are also responsible to confirm any appointments made through our website. We are not responsible for assuring that your appointment is in fact scheduled.</strong></p>
                            </li>
                            <li>
                                <h6 className="fw-semibold">No Warranties</h6>
                                <p>THE INFORMATION ON THIS SITE AND THE SERVICES ARE PROVIDED "AS IS" WITHOUT ANY WARRANTY, CONDITION OR REPRESENTATION OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, ANY WARRANTY RESPECTING NON-INFRINGEMENT, AND THE IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. IN NO EVENT SHALL <span className="trademark">openDoctor</span> OR ITS SUBSIDIARIES BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES HOWSOEVER CAUSED WHETHER ARISING IN CONTRACT, TORT OR OTHERWISE, ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE INFORMATION CONTAINED IN THIS SITE.</p>
                                <p>Use of the terms "partner" and "partnering" on this web site is not intended to convey or imply the existence of a legal partnership or joint venture relationship with any entity.</p>
                            </li>
                            <li>
                                <h6 className="fw-semibold">Trademarks</h6>
                                <p>Unless otherwise indicated, all trademarks found in the web site are trademarks of <span className="trademark">openDoctor</span>.</p>
                            </li>
                            <li>
                                <h6 className="fw-semibold">Termination</h6>
                                <p>We may terminate and/or suspend your registration immediately, without notice, if there has been a violation of these Terms of Service or other policies and terms posted on the Site by you or by someone using your Credentials. We may also cancel or suspend your registration for any other reason, including inactivity for an extended period, but will attempt to notify you in advance of such cancellation or suspension. <span className="trademark">openDoctor</span> shall not be liable to you or any third party for any termination of your access to the Site and/or the Services. Further, you agree not to attempt to use the Site and/or the Services after any such deletion, deactivation or termination. Sections 4, 6, 7, 10, 11, 13, 14 and 16 shall survive any termination or expiration of these Terms of Service.</p>
                            </li>
                            <li>
                                <h6 className="fw-semibold">Indemnity</h6>
                                <p>You understand that you are personally responsible for your behavior while on the Site and agree to indemnify and hold <span className="trademark">openDoctor</span>, and its affiliates, business partners, and their respective officers, directors, employees, and agents, harmless from and against any loss, damage, liability, cost, or expense of any kind (including attorneys' fees) that we may incur in connection with a third party claim or otherwise, in relation to your use of the Services or access to the Site, or your violation of either these TOS, applicable law or the rights of any third party.</p>
                            </li>
                        </ol>

                        <div className="lastUpdated">
                            <h5 className="fw-semibold">QUESTIONS OR ADDITIONAL INFORMATION </h5>
                            <p>If you have questions regarding this Provider Agreement, wish to obtain additional information or require any other further assistance, please send an e-mail to <LinkMailto label="userssupport@opendr.com" mailto="mailto:userssupport@opendr.com" />.</p>
                            <p>LAST UPDATED: <TermsPrivacyUpdatedDate /></p>
                            <p>Copyright (©) {new Date().getFullYear()} <span className="trademark">openDoctors <span className="opDocTrade">24<span>7</span> , Inc</span>. All rights reserved.</span></p></div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default TermsConditions