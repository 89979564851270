import React from 'react';
import { NavLink } from 'react-router-dom';
import PageBanner from '../components/PageBanner';
import NewsUpdatesDetailPage from '../components/NewsUpdatesDetailPage';
import NewsUpdatesBackButton from '../components/NewsUpdatesBackButton';

const NewsUpdatesContent4 = () => {
    const newsContent = {
        title: 'Tower Radiology and openDoctor Partner for Online Scheduling',
        paragraphs: [
            <>
                TAMPA, FL (August 18, 2020) - Scheduling an appointment at Tower Radiology just got easier with a new online scheduling experience through the openDoctor platform. Online scheduling is easy, fast, and convenient for patients to set an appointment. The new online feature is available 24/7, provides appointment confirmation, and paperless preregistration. This exciting new tool enhances the patient experience.
            </>,
            <>
                <em>“With COVID-19, the healthcare industry is required to adopt a new perspective on how patients and staff interact safely. It's incredible to offer our patients the ability to schedule their appointments online easily and at their convenience. Both Tower's and openDoctors' staff has done a fantastic job of integrating this latest feature to our collection of online and virtual tools available to patients. These virtual tools are an example of how Tower continues to set the standard in outpatient radiology!” said Larry Smith, Tower's CEO.</em>
            </>,
            <>
                <em>Joe Marino, CEO of openDoctor, stated that “In uncertain times, we're seeing industry leaders like Tower Radiology accelerate the adoption of digital, contactless tools to create a safer patient experience. Our patient engagement suite is designed to be utilized by patients on their laptops and smartphones, reducing the number of items they would typically touch in the patient waiting room. We believe this level of comfort will continue to build consumer confidence to return for their exams”.</em>
            </>,
            <>
                Patients will now experience, the ability to create an appointment 24/7, appointment confirmation, paperless preregistration, a virtual waiting room, decrease contact with staff, continue social distancing, and shorter wait times in the lobby.
            </>,
            <>
                <p className='mb-2 fw-semibold'>About Tower Radiology</p>
                Established in 1992, Tower Radiology provides quality radiology services in a comfortable, convenient, outpatient setting throughout Hillsborough, Pasco and East Pinellas Counties. Accredited by the American College of Radiology, Tower offers specialized services such as 3D mammography, high field wide-bore MRI, weight-bearing open MRI, extremity open MRI, cardiac MRI, CT low-dose lung screening and PET/CT. Tower's 65 plus board-certified radiologists hold certifications in all 11 radiology subspecialties and participate in the academic missions of research and teaching through serving as faculty for USF College of Medicine, Department of Radiology. For more information, go to <NavLink to="http://www.towerradiologycenters.com/" target="_blank" rel="noreferrer noopener">TowerRadiologyCenters.com</NavLink>.
            </>,
            <>
                <p className='mb-2 fw-semibold'>About OpenDoctor<br /></p>
                openDoctor is expanding its footprint in outpatient imaging, providing real-time patient access, referring engagement tools specifically designed to improve workflow and operational efficiency. The company goal is to bridge the communication gaps between imaging centers, referring providers and patients by providing a unified, digital platform for sharing data. For more information, go to <NavLink to="https://opendr.com/">opendr.com</NavLink>.
            </>,
            <>
                <div className='col-md-12 text-center'>
                    <NewsUpdatesBackButton />
                </div>
            </>
        ],
    };

    return (
        <div>
            <PageBanner title="News and Updates" />
            <NewsUpdatesDetailPage title={newsContent.title} paragraphs={newsContent.paragraphs} />
        </div>
    )
}
export default NewsUpdatesContent4