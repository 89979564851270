import React from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import PageBanner from '../components/PageBanner';
import NewsUpdatesDetailPage from '../components/NewsUpdatesDetailPage';
import NewsUpdatesBackButton from '../components/NewsUpdatesBackButton';

const NewsUpdatesNews2 = () => {
    const newsContent = {
        title: 'Welch Road Imaging Integrates RamSoft PowerServer RIS/PACS With openDoctor',
        paragraphs: [
            <>
                Feb 20th, 2019 Welch Road Imaging in California recently became the first RamSoft customer to integrate openDoctor with its PowerServer radiology information system (RIS)/picture archiving and communication system (PACS). With openDoctor, PowerServer RIS/PACS sends reminders and instructions regarding imaging appointments to patients via email, text and voice.
            </>,
            <>
                Integrating openDoctor with PowerServer RIS/PACS cuts down on no-shows, automates confirmations, eliminates scheduler/front desk inefficiencies and increases overall patient satisfaction. After the appointment, a customizable survey can be sent, empowering imaging facilities to gather key performance indicators (KPIs) and collect data to help improve their practice.
            </>,
            <>
                "Over 75 percent of patients are now receiving appointment reminders and confirmations through openDoctor. We have also seen a change at the front desk - fewer calls, and follow-ups needed - freeing up the scheduler to tend to other tasks," stated Lauren Raabe, scheduler at Welch Road.
            </>,
            <>
                <div className='col-md-12 text-center'>
                    <NewsUpdatesBackButton />
                    <NavLink to="https://www.itnonline.com/content/welch-road-imaging-integrates-ramsoft-powerserver-rispacs-opendoctor" target="_blank"><Button className="button large orange mx-0 mx-md-3">READ THE FULL ARTICLE</Button></NavLink>
                </div>
            </>
        ],
    };
    return (
        <div>
            <PageBanner title="News and Updates" />
            <NewsUpdatesDetailPage title={newsContent.title} paragraphs={newsContent.paragraphs} />
        </div>
    )
}
export default NewsUpdatesNews2