import React from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import PageBanner from '../components/PageBanner';
import NewsUpdatesDetailPage from '../components/NewsUpdatesDetailPage';
import NewsUpdatesBackButton from '../components/NewsUpdatesBackButton';

const NewsUpdatesBlog1 = () => {
    const newsContent = {
        title: 'Text Reminders Prove to Reduce No-Show Rates for Imaging Services',
        paragraphs: [
            <>
                In a recent study performed by Harvard Medical School and Mass General Hospital, text message reminders proved to reduce no-show rates for patients scheduled for MRI exams.
            </>,
            <>
                A recent study conducted by the Harvard Medical School and Mass General Hospital, published in the Journal of the American College of Radiology, found that text message reminders proved to reduce no-show rates for patients scheduled for MRI exams. No-shows are one of the largest contributing factors to waste in healthcare, "disrupting carefully crafted clinic schedules and realizing poorer patient outcomes when they go without care." These no-shows prove to be extremely costly for practices that perform a large volume of exams.
            </>,
            <>
                "We perform approximately 45,000 outpatient MRIs across all of our facilities annually, translating into about $25 million in revenue," said lead author and medical informatics analyst Chang Liu, adding "decreasing outpatient MRI no-shows from 5.1 percent to 3.8 percent means nearly $325,000 in increased revenues annually. What's more, the potential for savings is even greater with increasing text message adoption and application of the system to other imaging modalities."
            </>,
            <>
                Clearly, even a seemingly minuscule change in no-show rates can generate significant increases in practice revenue. We're now in an age of the ubiquitous cell phone, with 94% of Americans estimated to own one. It is a wasted chance and a missed opportunity at thousands, if not millions, in potential revenue to not pursue communications via these devices that everyone carries with them. openDoctor provides a mobile application offering fully customizable reminders by text message, call, or email, specially designed to make sure your reach your patients in the most efficient and effective matter, ensuring that they are showing up on time and prepared.
            </>,
            <>
                <div className='col-md-12 text-center'>
                    <NewsUpdatesBackButton />
                    <NavLink to="http://www.radiologybusiness.com/topics/practice-management/quality/text-reminders-can-reduce-patient-no-shows-imaging-centers" target="_blank"><Button className="button large orange mx-0 mx-md-3">READ THE FULL ARTICLE</Button></NavLink>
                </div>
            </>
        ],
    };

    return (
        <div>
            <PageBanner title="News and Updates" />
            <NewsUpdatesDetailPage title={newsContent.title} paragraphs={newsContent.paragraphs} />
        </div>
    )
}
export default NewsUpdatesBlog1