import React from 'react';
import HomeBanner from '../components/HomeBanner';
import HomeAnimationFeatures from '../components/HomeAnimationFeatures';
import MultipleLocation from '../components/MultipleLocation';
import HomeAnimationBlock from '../components/HomeAnimationBlock';
//import { Helmet } from 'react-helmet';

export const Home = () => {
    return (
        <div className="App home">
            {/*<Helmet>
                <script type="text/javascript" src="animation.js"></script>
                <script type="text/javascript" src="jquery-2.2.4.min.js"></script>
            </Helmet>*/}
            <HomeBanner />
            <HomeAnimationFeatures />
            <MultipleLocation />
            <HomeAnimationBlock />
        </div>
    )
}
export default Home;
