import React from 'react';
import { NavLink } from 'react-router-dom';
import PageBanner from '../components/PageBanner';
import NewsUpdatesDetailPage from '../components/NewsUpdatesDetailPage';
import NewsUpdatesBackButton from '../components/NewsUpdatesBackButton';

const NewsUpdatesContent3 = () => {
    const newsContent = {
        title: 'Infinx Healthcare and openDoctor Partner on an Innovative Approach to Patient Access',
        paragraphs: [
            <>
                SAN JOSE, Calif., Sept. 15, 2020 /PRNewswire/ — In an innovative move to bring together two world-class patient access solutions, openDoctor and <NavLink to="https://c212.net/c/link/?t=0&amp;l=en&amp;o=2916756-1&amp;h=3088517779&amp;u=http%3A%2F%2Fwww.infinx.com%2F&amp;a=Infinx+Healthcare" rel="noreferrer noopener" target="_blank">Infinx Healthcare</NavLink> have partnered to offer a fully integrated and automated scheduling and patient engagement platform coupled with industry-leading prior authorization capabilities.
            </>,
            <>
                openDoctor, the premier patient engagement and scheduling portal solution, and Infinx Healthcare, an industry leader in prior authorizations utilizing augmented intelligence, are teaming up to offer a streamlined patient experience that focuses on the patient onboarding process for imaging centers and radiology practices.
            </>,
            <>
                Using advanced scheduling logic, openDoctor supports real-time appointment scheduling workflow for complex, multi-facility healthcare groups. By bringing a cloud-based patient experience, openDoctor weaves insurance eligibility, virtual check-in, paperless registration, and online payments into a smoothly integrative patient access tool with matchless capabilities.
            </>,
            <>
                Infinx offers a proprietary and automated Prior Authorization Software solution that can determine if a diagnostic imaging procedure requires a prior authorization for the majority of national payers and large local payers, creating a fully complementary patient access experience. The Infinx Prior Authorization Software can reduce the time spent on prior authorizations and optimize patient scheduling for the diagnostic imaging procedure, utilizing a combination of artificial intelligence and automation to determine an authorization requirement in minutes, if not seconds, and with a high level of precision.
            </>,
            <>
                Together, these powerhouse technologies enable a smoother patient encounter from the initial contact. openDoctor securely processes the request sending an auto-notification to the patient, via text or email, with scheduling availability for the specific exam. The patient can then schedule immediately from any device.
            </>,
            <>
                By proactively contacting patients makes scheduling as easy as possible, openDoctor and Infinx maximize referral retention and enhance the patient experience. Our robust and integrative systems ensure maximum reimbursement through compliance with up-to-the-minute payer requirements while bringing efficiency and cost savings to the bottom line.
            </>,
            <>
                Joe Marino, CEO of openDoctor, stated that “In uncertain times, we're seeing industry leaders accelerate the adoption of digital, contactless tools to create a safer patient experience. Our patient engagement suite is designed to be utilized by patients on their laptops and smartphones, reducing the number of items they would typically touch in the patient waiting room. We believe this level of comfort will continue to build consumer confidence to return for their exams.”
            </>,
            <>
                Navaneeth Nair, VP of Products at Infinx said, “Healthcare is moving into automation and digitizing whenever and wherever possible—as it should. That's the way to higher-quality patient care. Prior authorization has always been one of the most challenging issues in healthcare. It presents delays and the potential for scheduling confusion, patient abandonment, denials, a drain on staff resources, and unpredictable revenue problems. Cleaning up this workflow is crucial for any provider or system and represents an opportunity to deliver strong value to its referring physicians.”
            </>,
            <>
                <p className='mb-2 fw-semibold'>About Infinx Healthcare</p>
                Infinx Healthcare provides innovative and scalable payment lifecycle solutions for hospitals, imaging centers, orthopedic and cardiology practices, and laboratories. Combining an intelligent, cloud-based platform driven by artificial intelligence and automation, leveraged by&nbsp;our trained and certified prior authorization, coding, and billing specialists, Infinx helps clients realize more revenue, enabling them to shift focus from administrative details to billable patient care.
            </>,
            <>
                For more information, visit <NavLink to="https://c212.net/c/link/?t=0&amp;l=en&amp;o=2916756-1&amp;h=2665367389&amp;u=http%3A%2F%2Fwww.infinx.com%2F&amp;a=www.infinx.com" rel="noreferrer noopener" target="_blank">www.infinx.com</NavLink>.
            </>,
            <>
                <p className='mb-2 fw-semibold'>About openDoctor</p>
                As early innovators and evangelist in the consumerism of healthcare, openDoctor brings the right amount of passion and experience to an industry in swift transition.&nbsp; openDoctor is expanding its footprint in outpatient imaging, providing real-time patient access, referring engagement tools specifically designed to improve workflow and operational efficiency.&nbsp; The company's goal is to bridge the communication gaps between imaging centers, referring providers and patients by providing a unified, digital platform for sharing data.&nbsp;
            </>,
            <>
                <div className='col-md-12 text-center'>
                    <NewsUpdatesBackButton />
                </div>
            </>
        ],
    };

    return (
        <div>
            <PageBanner title="News and Updates" />
            <NewsUpdatesDetailPage title={newsContent.title} paragraphs={newsContent.paragraphs} />
        </div>
    )
}
export default NewsUpdatesContent3