import React from 'react';
import PageBanner from '../components/PageBanner';
import NewsUpdatesDetailPage from '../components/NewsUpdatesDetailPage';
import NewsUpdatesBackButton from '../components/NewsUpdatesBackButton';

const NewsUpdatesContent9 = () => {
    const newsContent = {
        title: 'ScriptSender Partners with openDoctor to Deliver Streamlined Scheduling Workflow',
        paragraphs: [
            <>
                WEST PALM BEACH, Fla.,&nbsp;Dec. 2, 2019&nbsp;/PRNewswire/ —&nbsp;ScriptSender, a leader in enabling secure, one-click communication between healthcare providers, today announced a partnership with openDoctor, a leader in scheduling technology for the radiology industry. The integration of openDoctor's scheduling platform and ScriptSender's flagship product will enable imaging providers to deliver innovative services to referring physicians, plus patients benefit from a consumer-friendly online scheduling experience.
            </>,
            <>
                Rather than printing a paper order, which the patient then has to manage, a referring physician can use ScriptSender technology to send a digital order to a radiology practice with one click. With ScriptSender, clinicians can send any document, from any application and from any medical group, by using a print command. They can bypass the complexities and expense of complicated integrations—and eliminate the inefficiency of faxing.
            </>,
            <>
                Once the order is sent, the data—digital data, not a .pdf or image—goes directly to the RIS in the imaging center. openDoctor securely sends an auto-notification to the patient, via text or email, with scheduling availability for the specific exam. The patient can schedule immediately from any device. By pro-actively contacting patients and making the scheduling as easy as possible, openDoctor maximizes referral retention and enhances the patient experience.
            </>,
            <>
                After the exam, ScriptSender securely sends reports and results back to the referrer's EMR or to openDoctor's Physician Portal for easy access.
            </>,
            <>
                Joe Marino, CEO at openDoctor, said, “Together, we' bring an innovative and seamless game changer solution for referring physicians, and no one else in the marketplace is engaging radiology clients in this way. It's a turnkey solution for digital ordering, patient engagement, and fast report turnarounds.”
            </>,
            <>
                “Patients deserve the same—or better—level of consumer engagement that they receive in any other industry,” said&nbsp;Jim Burchfield, ScriptSender's Senior VP of Sales and Business Development. “openDoctor has expertise with engagement, and when combined with the automation of ScriptSender, the result is an exceptionally easy and pleasant patient experience. Our technologies complement each other, providing customer-centric innovations that solve some of the biggest delays in radiology workflows.”
            </>,
            <>
                ScriptSender customers will have the option to augment their workflow with openDoctor's scheduling platform. Likewise, openDoctor customers will be able to add ScriptSender's one-click integration technology to streamline communication with referrers.
            </>,
            <>
                <div className='col-md-12 text-center'>
                    <NewsUpdatesBackButton />
                </div>
            </>
        ],
    };

    return (
        <div>
            <PageBanner title="News and Updates" />
            <NewsUpdatesDetailPage title={newsContent.title} paragraphs={newsContent.paragraphs} />
        </div>
    )
}
export default NewsUpdatesContent9