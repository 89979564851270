import React from "react";

const FeatureBlock = props => {
    return (
        <div className="text-center pt-4 mb-4">
            <div className="overflow mb-4">
                <img src={props.imgsrc} alt="Image1" />
            </div>
            <div className="text-dark p-2">
                <h5 className="fw-semibold mb-3">{props.title}</h5>
                <p className="opacity-75">{props.text}</p>
            </div>
        </div>
    );
}

export default FeatureBlock;