import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import CaseStudiesBlock from './CaseStudiesBlock';

const CaseStudiesContent = () => {
    return (
        <div className="light-gray p-4 p-lg-5 p-sm-4">
            <Container fluid>
                <Row className="justify-content-md-center">
                    <Col md={9}>
                        <div className="text-center"><h4 class="fw-semibold">Case Studies</h4></div>
                        <CaseStudiesBlock title="Patients prefer to schedule appointments while offices are closed." text="A large radiology practice saw 50% of their patients who booked online through openDoctor occur outside of normal business office hours, thus converting patients from the website into appointments and expanding hours in which patients could schedule." />
                        <CaseStudiesBlock title="Multi-specialty clinics have various scheduling process some simple, some complex." text="Several multi-specialty clinics discovered that scheduling 'simple' specialties isn't easy - openDoctor helps them effectively handle appointments scheduled for new vs. returning patients, pediatric vs. adult, and scheduling advanced practitioners vs. residents vs. physicians correctly." />
                        <CaseStudiesBlock title="Two systems with interoperability issues" text="A large health system uses openDoctor to span two systems with interoperability issues, streamlining their internal scheduling processes and removing inefficient phone calls." />
                        <CaseStudiesBlock title="Multiple locations makes scheduling process complex" text="Helping patients find the best location for their exam can be difficult. Through openDoctor's proprietary functionality, our algorithms match patients with the right location for their exam for every appointment. " />
                        <CaseStudiesBlock title="Mobile Mammography online scheduling made easy" text="Mobile mammography appointments can be difficult to schedule. Our mobile client used openDoctor to achieve 90% online scheduling utilization." />
                        <CaseStudiesBlock title="Order-driven workflow to eliminate patient errors" text="Often when scheduling online appointments, the patient is responsible for choosing the exam type and expected to understand their exam at a high level. With an orders-driven workflow, openDoctor was able to assist a large radiology group to eliminate patient errors while scheduling outpatient exams." />
                        <CaseStudiesBlock title="Patient Forms utilization/intro to online experience" text="openDoctor works with large medical groups and health systems to help drive online access and engagement tools utilization. Our patient forms can be utilized for 100% of your patients, not just those who scheduled online. openDoctor clients have experienced upwards of 40% utilization of patient forms, thus creating an entry way for patients to manage their appointments online." />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CaseStudiesContent