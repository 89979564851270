import React, { useState, useEffect } from 'react';

const HomeAnimation = ({ images, interval }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, interval);

        return () => {
            clearTimeout(timer); // Clear the timeout on component unmount
        };
    }, [currentIndex, interval]);

    useEffect(() => {
        if (!isVisible) {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
            setIsVisible(true);
        }
    }, [isVisible, images]);
    return (
        <div className="image-carousel mt-5">
            {images.map((image, index) => (
                <img
                    key={index}
                    src={image}
                    alt={`Image ${index}`}
                    //className={currentIndex === index ? 'active' : ''}
                    className={`fade-image ${currentIndex === index ? 'active' : ''}`}
                    style={{ opacity: isVisible && currentIndex === index ? 1 : 0 }}
                />
            ))}
        </div>
    )
}

export default HomeAnimation