import React from 'react'
import InquiryForm from '../components/InquiryForm'

const Inquiry = () => {
    return (
        <div>
            <InquiryForm />
        </div>
    )
}

export default Inquiry