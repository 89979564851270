import React from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import PageBanner from '../components/PageBanner';
import NewsUpdatesDetailPage from '../components/NewsUpdatesDetailPage';
import NewsUpdatesBackButton from '../components/NewsUpdatesBackButton';

const NewsUpdatesNews3 = () => {
    const newsContent = {
        title: 'What matters most to the healthcare consumer?',
        paragraphs: [
            <>
                As the health care industry continues to shift from volume to value, and as consumers take a more active role in managing their health care, the need to understand what matters most to them grows.
            </>,
            <>
                Attempting to understand the decision-making processes for healthcare consumers has proven to be very complex, and more difficult than other industries. Currently, there is an industry-wide shift from quantity to quality. In a recent article published by Deloitte exploring their 2016 Consumer Priorities in Health Care Survey, we find that consumers are increasingly requiring "greater personalization; transparency in network coverage, medical prices, and bills; convenience; and more engaging digital experiences and capabilities."
            </>,
            <>
                Patients are asking more from their providers and 'timesaving conveniences' are ranked amongst the highest priorities in the study. The article continues on, noting, "As technology advances in other areas, consumers expect the same of health care. If they can book a flight from their mobile device, why not a doctor's appointment?" These needs directly align with the efforts we are making at openDoctor to help increase patient engagement and access through digital tools such as real-time online scheduling, automated appointment reminders, and customizable online forms.
            </>,
            <>
                The demand for increased patient access by providers inherently grows with the patient demand for higher quality care. openDoctor is working to bridge this gap between complex scheduling environments and a consumer-friendly online experience by offering the right tools and services to both healthcare organizations and their patients.
            </>,
            <>
                <div className='col-md-12 text-center'>
                    <NewsUpdatesBackButton />
                    <NavLink to="https://www2.deloitte.com/us/en/pages/life-sciences-and-health-care/articles/health-care-provider-consumer-experience-survey.html?cq_ck=1477684654635#top" target="_blank"><Button className="button large orange mx-0 mx-md-3">READ THE FULL ARTICLE</Button></NavLink>
                </div>
            </>
        ],
    };

    return (
        <div>
            <PageBanner title="News and Updates" />
            <NewsUpdatesDetailPage title={newsContent.title} paragraphs={newsContent.paragraphs} />
        </div>
    )
}
export default NewsUpdatesNews3