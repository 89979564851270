import { Component } from 'react';

class TermsPrivacyUpdatedDate extends Component {
    constructor() {
        super();
        this.state = {
            currentDate: new Date()
        };
    }

    render() {
        const { currentDate } = this.state;

        // Calculate the date three months ago
        const threeMonthsAgoDate = new Date(currentDate);
        threeMonthsAgoDate.setMonth(currentDate.getMonth() - 3);

        // Formatting options for the date
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };

        // Format the date to a string
        const formattedDate = threeMonthsAgoDate.toLocaleDateString('en-US', options);

        return (
            <>
                {formattedDate}
            </>
        );
    }
}

export default TermsPrivacyUpdatedDate;
