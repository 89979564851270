import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from "react-router-dom";

function NewsUpdatesContainer() {
    const [selectedCategory, setSelectedCategory] = useState('Filter by category');
    const categories = ['Filter by category', 'Blogs', 'Case Studies', 'News', 'Uncategorized', 'Updates'];
    const contentData = [
        {
            category: 'Filter by category',
            text: (
                <div>
                    <div className="justify-content-md-center row">
                        <div className="col-lg-5">
                            <h4><NavLink to="/news-updates/opendoctor-and-steinberg-diagnostic-join-forces-for-ordering-and-scheduling-automation">openDoctor and Steinberg Diagnostic Join Forces for Ordering and Scheduling Automation</NavLink></h4>
                            <p>Steinberg Diagnostic Medical Imaging first in Southern Nevada to offer Online Scheduling for Radiology</p>
                            <p>NEW YORK, March 17, 2021 /PRNewswire/ — Steinberg Diagnostic Medical Imaging (SDMI), Las Vegas's leading outpatient radiology practice, has partnered with openDoctor to offer real-time online patient scheduling, allowing patients and referring physicians to schedule outpatient radiology appointments at each of their 9 locations.</p>
                            <NavLink className="morelink" to="/news-updates/opendoctor-and-steinberg-diagnostic-join-forces-for-ordering-and-scheduling-automation">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4 d-block d-lg-none"></div>
                            <h4><NavLink to="/news-updates/philips-and-opendoctor-partner-to-deliver-unprecedented-digital-front-door-experience-in-radiology">Philips and openDoctor partner to deliver unprecedented digital front door experience in radiology</NavLink></h4>
                            <p><strong>March 9th, 2021</strong> </p>
                            <p><strong>Amsterdam, the Netherlands and New York, USA</strong>&nbsp;-&nbsp;<NavLink rel="noreferrer noopener" to="http://www.philips.com/newscenter" target="_blank">Royal Philips</NavLink>&nbsp;(NYSE: PHG, AEX: PHIA), a global leader in health technology, and US-based&nbsp;<NavLink rel="noreferrer noopener" to="https://opendr.com/" target="_blank">openDoctor</NavLink>&nbsp;today announced a partnership to deliver an integrated radiology patient engagement platform that leverages openDoctor's real-time online appointment scheduling services as part of Philips' newly launched&nbsp;<NavLink rel="noreferrer noopener" to="https://www.usa.philips.com/a-w/about/news/archive/standard/news/press/2020/20201129-philips-patient-management-solution-keeps-patients-safe-and-personalizes-care-during-covid-19-at-leading-u-s-medical-centers.html" target="_blank">Patient Management Solution</NavLink>.</p>
                            <NavLink className="morelink" to="/news-updates/philips-and-opendoctor-partner-to-deliver-unprecedented-digital-front-door-experience-in-radiology">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4"></div>
                            <h4><NavLink to="/news-updates/infinx-healthcare-and-opendoctor-partner-on-an-innovative-approach-to-patient-access">Infinx Healthcare and openDoctor Partner on an Innovative Approach to Patient Access</NavLink></h4>
                            <p>SAN JOSE, Calif.,&nbsp;Sept. 15, 2020&nbsp;/PRNewswire/ — In an innovative move to bring together two world-className patient access solutions, openDoctor and&nbsp;<NavLink to="https://c212.net/c/link/?t=0&amp;l=en&amp;o=2916756-1&amp;h=3088517779&amp;u=http%3A%2F%2Fwww.infinx.com%2F&amp;a=Infinx+Healthcare" rel="noreferrer noopener" target="_blank">Infinx Healthcare</NavLink>&nbsp;have&nbsp;partnered to offer a fully integrated and automated scheduling and patient engagement platform coupled with industry-leading prior authorization capabilities.</p>
                            <NavLink className="morelink" to="/news-updates/infinx-healthcare-and-opendoctor-partner-on-an-innovative-approach-to-patient-access">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4"></div>
                            <h4><NavLink to="/news-updates/tower-radiology-and-opendoctor-partner-for-online-scheduling">Tower Radiology and openDoctor Partner for Online Scheduling</NavLink></h4>
                            <p>TAMPA, FL (August 18, 2020) -Scheduling an appointment at Tower Radiology just got easier with a new online scheduling experience through the openDoctor platform. Online scheduling is easy, fast, and convenient for patients to set an appointment. The new online feature is available 24/7, provides appointment confirmation, and paperless preregistration. This exciting new tool enhances the patient experience.</p>
                            <NavLink className="morelink" to="/news-updates/tower-radiology-and-opendoctor-partner-for-online-scheduling">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4"></div>
                            <h4><NavLink to="/news-updates/opendoctor-adds-virtual-registration-client">openDoctor adds Virtual Registration Client</NavLink></h4>
                            <p>Tower Radiology is proud to announce a new Virtual Registration process for patients who schedule appointments at one of the multiple locations throughout the Tampa Bay area.</p>
                            <NavLink className="morelink" to="/news-updates/opendoctor-adds-virtual-registration-client">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4"></div>
                            <h4><NavLink to="/news-updates/imaginesoftware-and-opendoctor-announce-a-strategic-partnership-streamlining-patient-engagement">ImagineSoftware and openDoctor Announce a Strategic Partnership Streamlining Patient Engagement</NavLink></h4>
                            <p><strong>CHARLOTTE, NC: June 2020 -&nbsp;</strong>ImagineSoftware and openDoctor announce a Strategic Partnership streamlining patient engagement by integrating openDoctor's portal with ImagineSoftware's insurance eligibility processing service, contactless patient payment tool and patient responsibility estimator.</p>
                            <NavLink className="morelink" to="/news-updates/imaginesoftware-and-opendoctor-announce-a-strategic-partnership-streamlining-patient-engagement">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4"></div>
                            <h4><NavLink to="/news-updates/an-important-statement-regarding-covid-19-opendoctor-is-here-to-help">An Important Statement regarding COVID-19, openDoctor is here to help</NavLink></h4>
                            <p>A statement from openDoctor CEO, Joe Marino:<br /></p>
                            <NavLink className="morelink" to="/news-updates/an-important-statement-regarding-covid-19-opendoctor-is-here-to-help">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4"></div>
                            <h4><NavLink to="/news-updates/siim-2020-to-feature-opendoctor-production-improvement-by-patient-online-appointment-scheduling-abstract">SIIM 2020 to Feature openDoctor Production Improvement by Patient Online Appointment Scheduling Abstract</NavLink></h4>
                            <p>Alberto F. Goldszal, PhD, University Radiology Group, PC; Lisa Martinez; Avanthi Hulugalle; Vriti Bahirwani;<br /> Murray D. Becker, MD, PhD<br /></p>
                            <NavLink className="morelink" to="/news-updates/siim-2020-to-feature-opendoctor-production-improvement-by-patient-online-appointment-scheduling-abstract">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4"></div>
                            <h4><NavLink to="/news-updates/scriptsender-partners-with-opendoctor-to-deliver-streamlined-scheduling-workflow">ScriptSender Partners with openDoctor to Deliver Streamlined Scheduling Workflow</NavLink></h4>
                            <p>WEST PALM BEACH, Fla.,&nbsp;Dec. 2, 2019&nbsp;/PRNewswire/ —&nbsp;ScriptSender, a leader in enabling secure, one-click communication between healthcare providers, today announced a partnership with openDoctor, a leader in scheduling technology for the radiology industry. The integration of openDoctor's scheduling platform and ScriptSender's flagship product will enable imaging providers to deliver innovative services to referring physicians, plus patients benefit from a consumer-friendly online scheduling experience.</p>
                            <NavLink className="morelink" to="/news-updates/scriptsender-partners-with-opendoctor-to-deliver-streamlined-scheduling-workflow">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4"></div>
                            <h4><NavLink to="/news-updates/medcurrent-partners-with-opendoctor-to-enable-seamless-radiology-clinical-decision-support-for-ordering-providers">MedCurrent Partners with openDoctor to Enable Seamless Radiology Clinical Decision Support for Ordering Providers</NavLink></h4>
                            <p>TORONTO (PRWEB)&nbsp;NOVEMBER 26, 2019 MedCurrent Corporation, a global leader in radiology Clinical Decision Support (CDS) solutions, today announced a partnership with openDoctor, a leader in patient scheduling automation and referring provider engagement technology. As a result, MedCurrent's OrderWise® CDS solution will be integrated into openDoctor's physician engagement platform to give ordering providers a seamless way to meet the Centers for Medicare &amp; Medicaid Services (CMS) Appropriate Use Criteria (AUC) mandate for advanced imaging set to begin in January 2020.</p>
                            <NavLink className="morelink" to="/news-updates/medcurrent-partners-with-opendoctor-to-enable-seamless-radiology-clinical-decision-support-for-ordering-providers">Read More</NavLink>
                        </div>
                    </div>
                </div>
            ),
        },

        {
            category: 'Blogs',
            text: (
                <div>
                    <div className="justify-content-md-center row">
                        <div className="col-lg-5">
                            <h4><NavLink to="/news-updates/text-reminders-prove-to-reduce-no-show-rates-for-imaging-services">Text Reminders Prove to Reduce No-Show Rates for Imaging Services</NavLink></h4>
                            <p>In a recent study performed by Harvard Medical School and Mass General Hospital, text message reminders proved to reduce no-show rates for patients scheduled for MRI exams.</p>
                            <NavLink className="morelink" to="/news-updates/text-reminders-prove-to-reduce-no-show-rates-for-imaging-services">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4 d-block d-lg-none"></div>
                            <h4><NavLink to="/news-updates/opendoctor-hosts-online-symposium-focused-on-consumerism-in-radiology">openDoctor Hosts Online Symposium Focused on Consumerism in Radiology</NavLink></h4>
                            <p>On May 11th, openDoctor hosted another webinar in their ongoing series. </p>
                            <NavLink className="morelink" to="/news-updates/opendoctor-hosts-online-symposium-focused-on-consumerism-in-radiology">Read More</NavLink>
                        </div>
                    </div>
                </div>
            ),
        },

        {
            category: 'Case Studies',
            text: (
                <div>
                    <div className="justify-content-md-center row">
                        <div className="col-lg-5">
                            <h4><NavLink to="/news-updates/how-do-high-deductible-health-plans-affect-radiology">How do high-deductible health plans affect radiology?</NavLink></h4>
                            <p>Many providers experience serious challenges when trying to efficiently and accurately bill patients and payers.</p>
                            <NavLink className="morelink" to="/news-updates/how-do-high-deductible-health-plans-affect-radiology">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4 d-block d-lg-none"></div>
                            <h4><NavLink to="/news-updates/university-radiology-makes-strategic-investment-in-opendoctor">University Radiology Makes Strategic Investment in openDoctor</NavLink></h4>
                            <p>University Radiology, New Jersey's largest provider of subspecialty radiology services, today announced it has made a strategic investment in openDoctor, a leading provider of real-time access and engagement tools for ambulatory outpatient services.</p>
                            <NavLink className="morelink" to="/news-updates/university-radiology-makes-strategic-investment-in-opendoctor">Read More</NavLink>
                        </div>
                    </div>
                </div>
            ),
        },

        {
            category: 'News',
            text: (
                <div>
                    <div className="justify-content-md-center row">
                        <div className="col-lg-5">
                            <h4><NavLink to="/news-updates/patient-portal-adoption-tops-90-patient-registration-lags">Patient Portal Adoption Tops 90% Patient Registration Lags</NavLink></h4>
                            <p>June 19, 2019. Due to the modern ability for people to have information directly at their fingertips, we expect quick and easy access to everything. Patient data and medical records are no stranger to this type of technology. In 2017 around 90% of hospitals have installed a patient portal that allows you to book appointments, have access to medical records and lab results. Despite these innovations, data has shown that less than a quarter of patients actually use their portals.</p>
                            <NavLink className="morelink" to="/news-updates/patient-portal-adoption-tops-90-patient-registration-lags">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4 d-block d-lg-none"></div>
                            <h4><NavLink to="/news-updates/welch-road-imaging-integrates-ramsoft-powerserver-ris-pacs-opendoctor">Welch Road Imaging Integrates RamSoft PowerServer RIS/PACS With openDoctor</NavLink></h4>
                            <p>Feb 20th, 2019 Welch Road Imaging in California recently became the first RamSoft customer to integrate openDoctor with its PowerServer radiology information system (RIS)/picture archiving and communication system (PACS). With openDoctor, PowerServer RIS/PACS sends reminders and instructions regarding imaging appointments to patients via email, text and voice.</p>
                            <NavLink className="morelink" to="/news-updates/welch-road-imaging-integrates-ramsoft-powerserver-ris-pacs-opendoctor">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4"></div>
                            <h4><NavLink to="/news-updates/what-matters-most-to-the-healthcare-consumer">What matters most to the healthcare consumer?</NavLink></h4>
                            <p>As the health care industry continues to shift from volume to value, and as consumers take a more active role in managing their health care, the need to understand what matters most to them grows.</p>
                            <NavLink className="morelink" to="/news-updates/what-matters-most-to-the-healthcare-consumer">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4"></div>
                            <h4><NavLink to="/news-updates/opendoctor-lands-covia-health-agreement">openDoctor lands Covia Health agreement</NavLink></h4>
                            <p>October 13, 2016 - Software developer OpenDoctor has landed an agreement to provide Covia Health with its online patient scheduling software for Covia's mobile 3D mammography and bone densitometry services.</p>
                            <NavLink className="morelink" to="/news-updates/opendoctor-lands-covia-health-agreement">Read More</NavLink>
                        </div>
                    </div>
                </div>
            ),
        },

        {
            category: 'Uncategorized',
            text: (
                <div>
                    <div className="justify-content-md-center row">
                        <div className="col-lg-5">
                            <h4><NavLink to="/news-updates/opendoctor-and-steinberg-diagnostic-join-forces-for-ordering-and-scheduling-automation">openDoctor and Steinberg Diagnostic Join Forces for Ordering and Scheduling Automation</NavLink></h4>
                            <p>Steinberg Diagnostic Medical Imaging first in Southern Nevada to offer Online Scheduling for Radiology</p>
                            <p>NEW YORK, March 17, 2021 /PRNewswire/ — Steinberg Diagnostic Medical Imaging (SDMI), Las Vegas's leading outpatient radiology practice, has partnered with openDoctor to offer real-time online patient scheduling, allowing patients and referring physicians to schedule outpatient radiology appointments at each of their 9 locations.</p>
                            <NavLink className="morelink" to="/news-updates/opendoctor-and-steinberg-diagnostic-join-forces-for-ordering-and-scheduling-automation">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4 d-block d-lg-none"></div>
                            <h4><NavLink to="/news-updates/philips-and-opendoctor-partner-to-deliver-unprecedented-digital-front-door-experience-in-radiology">Philips and openDoctor partner to deliver unprecedented digital front door experience in radiology</NavLink></h4>
                            <p><strong>March 9th, 2021</strong> </p>
                            <p><strong>Amsterdam, the Netherlands and New York, USA</strong>&nbsp;-&nbsp;<NavLink rel="noreferrer noopener" to="http://www.philips.com/newscenter" target="_blank">Royal Philips</NavLink>&nbsp;(NYSE: PHG, AEX: PHIA), a global leader in health technology, and US-based&nbsp;<NavLink rel="noreferrer noopener" to="https://opendr.com/" target="_blank">openDoctor</NavLink>&nbsp;today announced a partnership to deliver an integrated radiology patient engagement platform that leverages openDoctor's real-time online appointment scheduling services as part of Philips' newly launched&nbsp;<NavLink rel="noreferrer noopener" to="https://www.usa.philips.com/a-w/about/news/archive/standard/news/press/2020/20201129-philips-patient-management-solution-keeps-patients-safe-and-personalizes-care-during-covid-19-at-leading-u-s-medical-centers.html" target="_blank">Patient Management Solution</NavLink>.</p>
                            <NavLink className="morelink" to="/news-updates/philips-and-opendoctor-partner-to-deliver-unprecedented-digital-front-door-experience-in-radiology">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4"></div>
                            <h4><NavLink to="/news-updates/infinx-healthcare-and-opendoctor-partner-on-an-innovative-approach-to-patient-access">Infinx Healthcare and openDoctor Partner on an Innovative Approach to Patient Access</NavLink></h4>
                            <p>SAN JOSE, Calif.,&nbsp;Sept. 15, 2020&nbsp;/PRNewswire/ — In an innovative move to bring together two world-className patient access solutions, openDoctor and&nbsp;<NavLink to="https://c212.net/c/link/?t=0&amp;l=en&amp;o=2916756-1&amp;h=3088517779&amp;u=http%3A%2F%2Fwww.infinx.com%2F&amp;a=Infinx+Healthcare" rel="noreferrer noopener" target="_blank">Infinx Healthcare</NavLink>&nbsp;have&nbsp;partnered to offer a fully integrated and automated scheduling and patient engagement platform coupled with industry-leading prior authorization capabilities.</p>
                            <NavLink className="morelink" to="/news-updates/infinx-healthcare-and-opendoctor-partner-on-an-innovative-approach-to-patient-access">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4"></div>
                            <h4><NavLink to="/news-updates/tower-radiology-and-opendoctor-partner-for-online-scheduling">Tower Radiology and openDoctor Partner for Online Scheduling</NavLink></h4>
                            <p>TAMPA, FL (August 18, 2020) -Scheduling an appointment at Tower Radiology just got easier with a new online scheduling experience through the openDoctor platform. Online scheduling is easy, fast, and convenient for patients to set an appointment. The new online feature is available 24/7, provides appointment confirmation, and paperless preregistration. This exciting new tool enhances the patient experience.</p>
                            <NavLink className="morelink" to="/news-updates/tower-radiology-and-opendoctor-partner-for-online-scheduling">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4"></div>
                            <h4><NavLink to="/news-updates/opendoctor-adds-virtual-registration-client">openDoctor adds Virtual Registration Client</NavLink></h4>
                            <p>Tower Radiology is proud to announce a new Virtual Registration process for patients who schedule appointments at one of the multiple locations throughout the Tampa Bay area.</p>
                            <NavLink className="morelink" to="/news-updates/opendoctor-adds-virtual-registration-client">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4"></div>
                            <h4><NavLink to="/news-updates/imaginesoftware-and-opendoctor-announce-a-strategic-partnership-streamlining-patient-engagement">ImagineSoftware and openDoctor Announce a Strategic Partnership Streamlining Patient Engagement</NavLink></h4>
                            <p><strong>CHARLOTTE, NC: June 2020 -&nbsp;</strong>ImagineSoftware and openDoctor announce a Strategic Partnership streamlining patient engagement by integrating openDoctor's portal with ImagineSoftware's insurance eligibility processing service, contactless patient payment tool and patient responsibility estimator.</p>
                            <NavLink className="morelink" to="/news-updates/imaginesoftware-and-opendoctor-announce-a-strategic-partnership-streamlining-patient-engagement">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4"></div>
                            <h4><NavLink to="/news-updates/an-important-statement-regarding-covid-19-opendoctor-is-here-to-help">An Important Statement regarding COVID-19, openDoctor is here to help</NavLink></h4>
                            <p>A statement from openDoctor CEO, Joe Marino:<br /></p>
                            <NavLink className="morelink" to="/news-updates/an-important-statement-regarding-covid-19-opendoctor-is-here-to-help">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4"></div>
                            <h4><NavLink to="/news-updates/siim-2020-to-feature-opendoctor-production-improvement-by-patient-online-appointment-scheduling-abstract">SIIM 2020 to Feature openDoctor Production Improvement by Patient Online Appointment Scheduling Abstract</NavLink></h4>
                            <p>Alberto F. Goldszal, PhD, University Radiology Group, PC; Lisa Martinez; Avanthi Hulugalle; Vriti Bahirwani;<br /> Murray D. Becker, MD, PhD<br /></p>
                            <NavLink className="morelink" to="/news-updates/siim-2020-to-feature-opendoctor-production-improvement-by-patient-online-appointment-scheduling-abstract">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4"></div>
                            <h4><NavLink to="/news-updates/scriptsender-partners-with-opendoctor-to-deliver-streamlined-scheduling-workflow">ScriptSender Partners with openDoctor to Deliver Streamlined Scheduling Workflow</NavLink></h4>
                            <p>WEST PALM BEACH, Fla.,&nbsp;Dec. 2, 2019&nbsp;/PRNewswire/ —&nbsp;ScriptSender, a leader in enabling secure, one-click communication between healthcare providers, today announced a partnership with openDoctor, a leader in scheduling technology for the radiology industry. The integration of openDoctor's scheduling platform and ScriptSender's flagship product will enable imaging providers to deliver innovative services to referring physicians, plus patients benefit from a consumer-friendly online scheduling experience.</p>
                            <NavLink className="morelink" to="/news-updates/scriptsender-partners-with-opendoctor-to-deliver-streamlined-scheduling-workflow">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4"></div>
                            <h4><NavLink to="/news-updates/medcurrent-partners-with-opendoctor-to-enable-seamless-radiology-clinical-decision-support-for-ordering-providers">MedCurrent Partners with openDoctor to Enable Seamless Radiology Clinical Decision Support for Ordering Providers</NavLink></h4>
                            <p>TORONTO (PRWEB)&nbsp;NOVEMBER 26, 2019 MedCurrent Corporation, a global leader in radiology Clinical Decision Support (CDS) solutions, today announced a partnership with openDoctor, a leader in patient scheduling automation and referring provider engagement technology. As a result, MedCurrent's OrderWise® CDS solution will be integrated into openDoctor's physician engagement platform to give ordering providers a seamless way to meet the Centers for Medicare &amp; Medicaid Services (CMS) Appropriate Use Criteria (AUC) mandate for advanced imaging set to begin in January 2020.</p>
                            <NavLink className="morelink" to="/news-updates/medcurrent-partners-with-opendoctor-to-enable-seamless-radiology-clinical-decision-support-for-ordering-providers">Read More</NavLink>
                        </div>
                    </div>
                </div>
            ),
        },

        {
            category: 'Updates',
            text: (
                <div>
                    <div className="justify-content-md-center row">
                        <div className="col-lg-5">
                            <h4><NavLink to="/news-updates/opendoctor-partners-zwanger-pesiri-real-time-patient-online-scheduling">openDoctor Partners With Zwanger Pesiri for Real-Time Patient Online Scheduling</NavLink></h4>
                            <p>March 5, 2019. Zwanger Pesiri, one of the nation's largest outpatient radiology practices and provider of subspecialty radiology services in the US, today announced it has chosen openDoctor to provide real-time online scheduling for new and existing patients.</p>
                            <NavLink className="morelink" to="/news-updates/opendoctor-partners-zwanger-pesiri-real-time-patient-online-scheduling">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4 d-block d-lg-none"></div>
                            <h4><NavLink to="/news-updates/patient-engagement-forefront-opendoctor-partnership-steinberg-diagnostic">Patient Engagement at the Forefront of openDoctor Partnership With Steinberg Diagnostic</NavLink></h4>
                            <p>Feb 27th, 2019. As the first in Las Vegas to partner with openDoctor, Steinberg Diagnostic Medical Imaging (SDMI), a leading outpatient radiology practice, continues to be at the forefront of technology.</p>
                            <NavLink className="morelink" to="/news-updates/patient-engagement-forefront-opendoctor-partnership-steinberg-diagnostic">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4"></div>
                            <h4><NavLink to="/news-updates/why-patients-wont-request-an-appointment-much-longer">Why Patients Won't "Request an Appointment" Much Longer</NavLink></h4>
                            <p>Check out our top reasons online self-scheduling is a critical next step in any healthcare provider's digital strategy.</p>
                            <NavLink className="morelink" to="/news-updates/why-patients-wont-request-an-appointment-much-longer">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4"></div>
                            <h4><NavLink to="/news-updates/what-does-consumerism-in-healthcare-look-like">What does consumerism in healthcare look like?</NavLink></h4>
                            <p>The interaction between healthcare organizations and patients is changing. What does it look like right now?</p>
                            <NavLink className="morelink" to="/news-updates/what-does-consumerism-in-healthcare-look-like">Read More</NavLink>
                        </div>
                    </div>
                </div>
            ),
        },
    ];

    const filteredContent = contentData.filter((item) => {
        return selectedCategory === 'All' || item.category === selectedCategory;
    });

    return (
        <div>
            <div className="pagebanner p-5 p-lg-5 p-sm-4 ">
                <Container className="my-2">
                    <Row className="justify-content-md-center text-center">
                        <Col md={10}>
                            <h2 className="fw-semibold mt-2 mb-3">
                                News and Updates
                            </h2>
                            <div>
                                <select className='custom-dropdown-button'
                                    value={selectedCategory}
                                    onChange={(e) => setSelectedCategory(e.target.value)}
                                >
                                    <option value="All">Show all</option>
                                    {categories.map((category) => (
                                        <option key={category} value={category}>
                                            {category}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


            <Container fluid className='p-5 newsupdate'>
                {filteredContent.map((item, index) => (
                    <div key={index}>{item.text}</div>
                ))}
            </Container>
        </div>
    );
}
export default NewsUpdatesContainer;