import React, { useState } from 'react';
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QuestionCircleFill } from 'react-bootstrap-icons';

const RoiCalculator = () => {
    const annualOutpatientAppointments = 10000;
    const defaultPercentageValues = [3, 15, 15];
    const defaultDollarAmountValues = [500, 100, 1000];
    const defaultDollarAmountCalculator = [500, 1000, 10000];
    const defaultPercentageCalculator = [300, 9750, 6375];


    const calculateTotal = (annualOutpatientAppointments, percentages, amounts) => {
        //const percentageSum = percentages.reduce((sum, value) => sum + value, 0);
        //const amountSum = amounts.reduce((sum, value) => sum + value, 0);
        const calculatedTotal = ((((((percentages[0] * amounts[0]) * 0.3) + percentages[1]) + percentages[2]) + amounts[1]) + amounts[2]);
        return calculatedTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    };

    const [numberValue, setNumberValue] = useState(annualOutpatientAppointments);
    const [percentageValues, setPercentageValues] = useState(defaultPercentageValues);
    const [dollarAmountValues, setDollarAmountValues] = useState(defaultDollarAmountValues);
    const [dollarAmountCalculator, setDollarAmountValuesCalculator] = useState(defaultDollarAmountCalculator);
    const [percentageCalculator, setPercentageCalculator] = useState(defaultPercentageCalculator);
    const [totalValue, setTotalValue] = useState(
        calculateTotal(annualOutpatientAppointments, defaultPercentageCalculator, defaultDollarAmountCalculator)
    );

    const handleNumberChange = (event) => {
      //  console.log(event);
        const newPercentageCalculator = [...percentageCalculator];
        const newDollerAmountCalculator = [...dollarAmountCalculator];

        let value = 10000;
        let idVal = event.target.id;

        //alert(event.target.value);

        if (event.target.value) {
            value = parseInt(event.target.value);
            setNumberValue(value);

            if (value < 10000) {
                value = 10000;

            } else if (value > 10000000) {
                value = 10000000;
                setNumberValue(value);
            }
        } else {
            setNumberValue("");
        }


        const projectedNoShow = ((percentageValues[0] * value) / 100);
        //console.log('projectedNoShow');
        newPercentageCalculator[0] = projectedNoShow;

        const paperlessPercentage = (((percentageValues[1] * value) / 100) * 6.5);
        //console.log(paperlessPercentage + 'paperlessFormsPercentage');
        newPercentageCalculator[1] = paperlessPercentage;

        const onlineSchedulingUtilizationRate = ((((percentageValues[2] * value) / 100) / 8000) * 34000);
       // console.log(onlineSchedulingUtilizationRate + 'onlineSchedulingUtilizationRate');
        newPercentageCalculator[2] = onlineSchedulingUtilizationRate;

        setPercentageCalculator(newPercentageCalculator);

        const lostValuePerNoShowVar = ((value * 0.001) * dollarAmountValues[1]);
        //console.log(lostValuePerNoShowVar);
        newDollerAmountCalculator[1] = lostValuePerNoShowVar;

        const valuePerPreferredPatient = ((value * 0.001) * dollarAmountValues[2]);
       // console.log(valuePerPreferredPatient + 'valuePerPreferredPatient');
        newDollerAmountCalculator[2] = valuePerPreferredPatient;

        setDollarAmountValuesCalculator(newDollerAmountCalculator);


        const newTotalValue = calculateTotal(value, newPercentageCalculator, newDollerAmountCalculator);
        setTotalValue(newTotalValue);

    };

    const handlePercentageChange = (index, event) => {
        const newPercentageCalculatorVar = parseInt(event.target.value);
        const percentageCalculatorID = event.target.name;
        const newPercentageCalculator = [...percentageCalculator];
        const newPercentageValues = [...percentageValues];
        
       // setPercentageValues(newPercentageValues);

        if (percentageCalculatorID == "projectedNoShowRate") {
            let value = 3;
            

           // alert(event.target.value);

            if (event.target.value != "%") {
                newPercentageValues[index] = parseInt(event.target.value.replace('%',''));
                value = parseInt(event.target.value.replace('%',''));
                setPercentageValues(newPercentageValues);

                if (value < 1) {
                    value = 1;

                } else if (value > 50) {
                    value = 50;
                    newPercentageValues[index] = 50;
                    setPercentageValues(newPercentageValues);
                }
            } else {
                newPercentageValues[index] = "";
                setPercentageValues(newPercentageValues);
            }



            const newPercentageCalculatedValue = ((value * numberValue) / 100);
           // console.log(index + 'projectedNoShow');
            newPercentageCalculator[index] = newPercentageCalculatedValue;          


        }
        else if (percentageCalculatorID == "paperlessFormsPercentage") {
            let value = 1;
            

            // alert(event.target.value);
 
             if (event.target.value != "%") {
                 newPercentageValues[index] = parseInt(event.target.value.replace('%',''));
                 value = parseInt(event.target.value.replace('%',''));
                 setPercentageValues(newPercentageValues);
 
                 if (value < 1) {
                     value = 1;
 
                 } else if (value > 100) {
                     value = 100;
                     newPercentageValues[index] = 100;
                     setPercentageValues(newPercentageValues);
                 }
             } else {
                 newPercentageValues[index] = "";
                 setPercentageValues(newPercentageValues);
             }


            const newPercentageCalculatedValue = (((value * numberValue) / 100) * 6.5);
           // console.log(newPercentageCalculatedValue + 'paperlessFormsPercentage');
            newPercentageCalculator[index] = newPercentageCalculatedValue;
        }
        else if (percentageCalculatorID == "onlineSchedulingUtilizationRate") {
            let value = 5;
            

            // alert(event.target.value);
 
             if (event.target.value != "%") {
                 newPercentageValues[index] = parseInt(event.target.value.replace('%',''));
                 value = parseInt(event.target.value.replace('%',''));
                 setPercentageValues(newPercentageValues);
 
                 if (value < 5) {
                     value = 5;
 
                 } else if (value > 100) {
                     value = 100;
                     newPercentageValues[index] = 100;
                     setPercentageValues(newPercentageValues);
                 }
             } else {
                 newPercentageValues[index] = "";
                 setPercentageValues(newPercentageValues);
             }


            const newPercentageCalculatedValue = ((((value * numberValue) / 100) / 8000) * 34000);
           // console.log(newPercentageCalculatedValue + 'onlineSchedulingUtilizationRate');
            newPercentageCalculator[index] = newPercentageCalculatedValue;
        }
        else {
            newPercentageCalculator[index] = parseFloat(event.target.value);
        }

        setPercentageCalculator(newPercentageCalculator);

        
        
        const newTotalValue = calculateTotal(numberValue, newPercentageCalculator, dollarAmountCalculator);
        setTotalValue(newTotalValue);
    };

    const handleDollarAmountChange = (index, event) => {
        const lostValuePerNoShowVar = parseFloat(event.target.value);
        const idOFVariable = event.target.name;
        const newDollerAmountCalculator = [...dollarAmountCalculator];
        const newDollarAmountValues = [...dollarAmountValues];
       // newDollarAmountValues[index] = parseFloat(event.target.value);
       

        if (idOFVariable == "lostValuePerNoShow") {
            let value = 100;
            

            //alert(event.target.value.replace('$',''));

            if (event.target.value != "$") {
                newDollarAmountValues[index] = parseInt(event.target.value.replace('$',''));
                value = parseInt(event.target.value.replace('$',''));
                setDollarAmountValues(newDollarAmountValues);

                if (value < 100) {
                    value = 100;

                } else if (value > 10000) {
                    value = 10000;
                    newDollarAmountValues[index] = 10000;
                    setDollarAmountValues(newDollarAmountValues);
                }
            } else {
                newDollarAmountValues[index] = "";
                setDollarAmountValues(newDollarAmountValues);
            }

            const lostValuePerNoShowVar = value;
           // console.log(index + 'lostValuePerNoShowIndexValue');
            newDollerAmountCalculator[index] = lostValuePerNoShowVar;
        }
        else if (idOFVariable == "patientLifetimeValue") {

            let value = 100;
            

            //alert(event.target.value.replace('$',''));

            if (event.target.value != "$") {
                newDollarAmountValues[index] = parseInt(event.target.value.replace('$',''));
                value = parseInt(event.target.value.replace('$',''));
                setDollarAmountValues(newDollarAmountValues);

                if (value < 100) {
                    value = 100;

                } else if (value > 10000) {
                    value = 10000;
                    newDollarAmountValues[index] = 10000;
                    setDollarAmountValues(newDollarAmountValues);
                }
            } else {
                newDollarAmountValues[index] = "";
                setDollarAmountValues(newDollarAmountValues);
            }

            const lostValuePerNoShowVar = ((numberValue * 0.001) * value);
           // console.log(lostValuePerNoShowVar);
            newDollerAmountCalculator[index] = lostValuePerNoShowVar;
        }

        //Added by sushma
        else if (idOFVariable == "valuePerPreferredPatient") {
            let value = 100;
            

            //alert(event.target.value.replace('$',''));

            if (event.target.value != "$") {
                newDollarAmountValues[index] = parseInt(event.target.value.replace('$',''));
                value = parseInt(event.target.value.replace('$',''));
                setDollarAmountValues(newDollarAmountValues);

                if (value < 100) {
                    value = 100;

                } else if (value > 10000) {
                    value = 10000;
                    newDollarAmountValues[index] = 10000;
                    setDollarAmountValues(newDollarAmountValues);
                }
            } else {
                newDollarAmountValues[index] = "";
                setDollarAmountValues(newDollarAmountValues);
            }

            const lostValuePerNoShowVar = ((numberValue * 0.001) * value);
           // console.log(lostValuePerNoShowVar + 'valuePerPreferredPatient');
            newDollerAmountCalculator[index] = lostValuePerNoShowVar;
        }
        //END

        else {
            newDollerAmountCalculator[index] = parseFloat(event.target.value);
        }
        

        setDollarAmountValuesCalculator(newDollerAmountCalculator);
        
        const newTotalValue = calculateTotal(numberValue, percentageCalculator, newDollerAmountCalculator);
        setTotalValue(newTotalValue);

    };

    return (
        <div className="slider-calculator pricing-background">
            <Container fluid>
                <Row>
                    <Col lg={6} md={6} className='roi-style'>
                        <div>
                            <label>Annual Outpatient Appointments &nbsp;
                                <OverlayTrigger overlay={<Tooltip id="tooltip">Total volume of appointments during a 12 month period.</Tooltip>}>
                                    <span className="d-inline-block">
                                        <QuestionCircleFill style={{ pointerEvents: 'none' }} />
                                    </span>
                                </OverlayTrigger>
                            </label>
                            <Row className='mb-4'>
                                <Col xs="8">
                                    <input
                                        id="annualOutpatientAppointments"
                                        type="range"
                                        min="10000"
                                        max="10000000"
                                        value={numberValue}
                                        onChange={handleNumberChange} />
                                </Col>
                                <Col xs="4">
                                    <input className='slidervalue'
                                        type='text'
                                        value={numberValue}
                                        onInput={handleNumberChange}
                                        min="10000"
                                        max="10000000"
                                        minLength="5"
                                        maxLength="8" />
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <label>Projected No Show Rate &nbsp;
                                <OverlayTrigger overlay={<Tooltip id="tooltip">This is your current no show rate during a 12 month period. This rated is reduced by a factor of 30% from the implementation of a notifications program.</Tooltip>}>
                                    <span className="d-inline-block">
                                        <QuestionCircleFill style={{ pointerEvents: 'none' }} />
                                    </span>
                                </OverlayTrigger>
                            </label>
                            <Row className='mb-4'>
                                <Col xs="8">
                                    <input
                                        
                                        id="projectedNoShowRate"
                                        name="projectedNoShowRate"
                                        type="range"
                                        min="1"
                                        max="50"
                                        value={percentageValues[0]}
                                        onChange={(event) => handlePercentageChange(0, event)}
                                    />
                                </Col>
                                <Col xs="4">
                                    <input 
                                        className='slidervalue'
                                        type='text'
                                        id="projectedNoShowRateText"
                                        name="projectedNoShowRate"
                                        value={percentageValues[0]+'%'}
                                        onInput={(event) => handlePercentageChange(0, event)}
                                        min="3"
                                        max="50"
                                        minLength="1"
                                        maxLength="3" />
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <label>Lost "Value" per No Show &nbsp;
                                <OverlayTrigger overlay={<Tooltip id="tooltip">Average lost revenue from a patient not showing up or showing up unprepared.</Tooltip>}>
                                    <span className="d-inline-block">
                                        <QuestionCircleFill style={{ pointerEvents: 'none' }} />
                                    </span>
                                </OverlayTrigger>
                            </label>
                            <Row className='mb-4'>
                                <Col xs="8">
                                    <input
                                        id="lostValuePerNoShow"
                                        name="lostValuePerNoShow"
                                        type="range"
                                        min="100"
                                        max="10000"
                                        value={dollarAmountValues[0]}
                                        onChange={(event) => handleDollarAmountChange(0, event)}
                                    />
                                </Col>
                                <Col xs="4">
                                    <input className='slidervalue'
                                        id="lostValuePerNoShowText"
                                        name="lostValuePerNoShow"
                                        type='text'
                                        value={'$' + dollarAmountValues[0]}
                                        onInput={(event) => handleDollarAmountChange(0, event)}
                                        min="100"
                                        max="10000"
                                        minLength="3"
                                        maxLength="6" />
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <label>Patient Lifetime Value &nbsp;
                                <OverlayTrigger overlay={<Tooltip id="tooltip">What is the value of a patient over time. </Tooltip>}>
                                    <span className="d-inline-block">
                                        <QuestionCircleFill style={{ pointerEvents: 'none' }} />
                                    </span>
                                </OverlayTrigger>
                            </label>
                            <Row className='mb-4'>
                                <Col xs="8">
                                    <input
                                        id="patientLifetimeValue"
                                        name="patientLifetimeValue"
                                        type="range"
                                        min="100"
                                        max="10000"
                                        value={dollarAmountValues[1]}
                                        onChange={(event) => handleDollarAmountChange(1, event)}
                                    />
                                </Col>
                                <Col xs="4">
                                    <input className='slidervalue'
                                        id="patientLifetimeValueText"
                                        name="patientLifetimeValue"
                                        type='text'
                                        value={'$' + dollarAmountValues[1]}
                                        onInput={(event) => handleDollarAmountChange(1, event)}
                                        min="100"
                                        max="10000"
                                        minLength="3"
                                        maxLength="6" />
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <label>Paperless Forms Percentage &nbsp;
                                <OverlayTrigger overlay={<Tooltip id="tooltip">What percent of patients can you move from paper forms to digital forms. We assumed cost of $6.50 per patient for paper forms. </Tooltip>}>
                                    <span className="d-inline-block">
                                        <QuestionCircleFill style={{ pointerEvents: 'none' }} />
                                    </span>
                                </OverlayTrigger>
                            </label>
                            <Row className='mb-4'>
                                <Col xs="8">
                                    <input
                                        id="paperlessFormsPercentage"
                                        name="paperlessFormsPercentage"
                                        type="range"
                                        min="1"
                                        max="100"
                                        value={percentageValues[1]}
                                        onChange={(event) => handlePercentageChange(1, event)}
                                    />
                                </Col>
                                <Col xs="4">
                                    <input className='slidervalue'
                                        id="paperlessFormsPercentageText"
                                        name="paperlessFormsPercentage"
                                        type='text'
                                        value={percentageValues[1] + '%'}
                                        onInput={(event) => handlePercentageChange(1, event)}
                                        min="1"
                                        max="100"
                                        minLength="2"
                                        maxLength="4" />
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <label>Value Per Preferred Patient &nbsp;
                                <OverlayTrigger overlay={<Tooltip id="tooltip">What is the value of a patient coming via a referred provider.</Tooltip>}>
                                    <span className="d-inline-block">
                                        <QuestionCircleFill style={{ pointerEvents: 'none' }} />
                                    </span>
                                </OverlayTrigger>
                            </label>
                            <Row className='mb-4'>
                                <Col xs="8">
                                    <input
                                        id="valuePerPreferredPatient"
                                        name="valuePerPreferredPatient"
                                        type="range"
                                        min="100"
                                        max="10000"
                                        value={dollarAmountValues[2]}
                                        onChange={(event) => handleDollarAmountChange(2, event)}
                                    />
                                </Col>
                                <Col xs="4">
                                    <input className='slidervalue'
                                        id="valuePerPreferredPatientText"
                                        name="valuePerPreferredPatient"
                                        type='text'
                                        value={'$' + dollarAmountValues[2]}
                                        onInput={(event) => handleDollarAmountChange(2, event)}
                                        min="100"
                                        max="10000"
                                        minLength="3"
                                        maxLength="6" />

                                </Col>
                            </Row>
                        </div>
                        <div>
                            <label>Online Scheduling Utilization Rate &nbsp;
                                <OverlayTrigger overlay={<Tooltip id="tooltip">Percentage of patients moved to online scheduling from Call Center scheduling. We assumed that a scheduler can handle 8k appointments a year and had an average cost of 34k per year.</Tooltip>}>
                                    <span className="d-inline-block">
                                        <QuestionCircleFill style={{ pointerEvents: 'none' }} />
                                    </span>
                                </OverlayTrigger>
                            </label>
                            <Row className='mb-4'>
                                <Col xs="8">
                                    <input
                                        id="onlineSchedulingUtilizationRate"
                                        name="onlineSchedulingUtilizationRate"
                                        type="range"
                                        min="5"
                                        max="100"
                                        value={percentageValues[2]}
                                        onChange={(event) => handlePercentageChange(2, event)}
                                    />
                                </Col>
                                <Col xs="4">
                                    <input className='slidervalue'
                                        id="onlineSchedulingUtilizationRateText"
                                        name="onlineSchedulingUtilizationRate"
                                        type='text'
                                        value={percentageValues[2] + '%'}
                                        onInput={(event) => handlePercentageChange(2, event)}
                                        min="5"
                                        max="100"
                                        minLength="1"
                                        maxLength="3" />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col lg={6} md={6} className='p-5 my-5'>
                        <div className='text-center'>
                            <label className='text-uppercase'>Potential Savings</label>
                            <input className='totalvaluedisplay mt-5' type="text" value={"$" + totalValue} readOnly />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default RoiCalculator