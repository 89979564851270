import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import CareersBlock from './CareersBlock';
import img1 from '../images/icons/competitivepay.png';
import img2 from '../images/icons/paidtimeoff.png';
import img3 from '../images/icons/healthbenefits.png';
import img4 from '../images/icons/learngrow.png';

import { NavLink } from "react-router-dom";

const CareersContent = () => {
  return (
    <>
      <div className='light-gray'>
        <Container fluid className="p-5 pb-0 careerimg">
          <div class="text-center">
            <h4 class="fw-semibold">Perks & Benefits</h4>
            <p>Working at openDoctor has its benefits.</p>
          </div>
          <Row>
            <CareersBlock imgsrc={img1} title="Competitive Pay" text="openDoctor provides highly competitive salaries and great options packages." />
            <CareersBlock imgsrc={img2} title="Paid Time Off" text="Our team is at its best when we're rested. We provide three weeks of vacation + holidays." />
            <CareersBlock imgsrc={img3} title="Health & Wellness" text="openDoctor offer employee health benefits." />
            <CareersBlock imgsrc={img4} title="Learn & Grow" text="We expect everyone to learn and grow. We provide opportunities to build new skills." />
          </Row>
        </Container>
      </div>
      <div>
        <Container fluid className="p-5">

          <div class="text-center mb-5">
            <h4 class="fw-semibold">Current Openings</h4>
            {/*
            <br /><br />
            <h5>Currently there are no open positions <br />
              Please check back in the future
            </h5>
            */}
          </div>

          <Row className="justify-content-md-center border-top py-4">
            <Col md={3}><h6 className='fw-semibold'>Full Stack Software Developer - MERN</h6></Col>
            <Col md={2} xs={6} className="mb-3">No. of openings - 2</Col>
            <Col md={2} xs={6}> Mumbai, India</Col>
            <Col lg={2} md={3}><NavLink to="/careers/full-stack-software-developer"><Button variant="outline-primary" id="button-addon1"> View Detail </Button></NavLink></Col>
          </Row>
          <Row className="justify-content-md-center border-top py-4">
            <Col md={3}><h6 className='fw-semibold'>Automation Test Engineer- Cypress</h6></Col>
            <Col md={2} xs={6} className="mb-3">No. of openings - 2</Col>
            <Col md={2} xs={6}> Mumbai, India</Col>
            <Col lg={2} md={3}><NavLink to="/careers/automation-test-engineer"><Button variant="outline-primary" id="button-addon1"> View Detail </Button></NavLink></Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default CareersContent