import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import PageBanner from '../components/PageBanner';
import { NavLink } from 'react-router-dom';

const FullStackSoftwareDeveloper = () => {
    return (
        <div>
            <PageBanner title="Careers" text="Let's bring healthcare into the 21st century" />
            <Container fluid className='p-5'>  <div className='text-center pb-5'><h4 className='fw-semibold'>Full Stack Software Developer - MERN</h4></div>
                <Row className='justify-content-md-center'>
                    <Col md={8} className="border-top pt-5">
                        <p>
                            To apply to this position, <a href="https://in.indeed.com/job/mern-stack-developer-01c9d762b7309f2c" target='_blank'>click here</a>
                        </p>
                        <p>
                            We are looking for a seasoned Full Stack Software Developer - MERN engineer to build out and manage our application. Successful candidates will have a minimum of four years recent experience with all of the skills listed below.
                        </p>

                        <h6 className='fw-semibold'>
                            Job Description / Responsibilities:
                        </h6>
                        <ul>
                            <li>
                                Design and build applications/systems based on wireframes and product requirements documents.
                            </li>
                            <li>
                                Work on back-end & front-end development of core scripts using NodeJS/ MariaDB/React.js/Express .
                            </li>
                            <li>
                                Hands on experience in writing Unit test code, including edge cases, usability and general reliability.
                            </li>
                            <li>
                                Must be familiar to use assistive AI tools such as GitHub Copilot / Amazon CodeWhispere / Vertex AI.
                            </li>
                            <li>
                                Write reusable, easy to maintain, versioned code using DRY principles.
                            </li>
                            <li>
                                Integrate existing tools and business systems (in-house tools).
                            </li>
                            <li>
                                Good exposure in creating dynamic web pages.
                            </li>
                            <li>
                                Familiarity with Server-Oriented Architecture and RESTful Web Services.
                            </li>
                            <li>
                                Mentor junior team members on system architecture, coding styles and inculcate an attitude of continuous improvement in the team members.
                            </li>
                        </ul>

                        <h6 className='fw-semibold'>
                            Desired profile of the candidate:
                        </h6>
                        <ul>
                            <li>
                                Ability to work independently.
                            </li>
                            <li>
                                Strong problem solving skills.
                            </li>
                            <li>
                                Knowledge of data structures and algorithms.
                            </li>
                            <li>
                                Hungry for more responsibility and knowledge.
                            </li>
                            <li>
                                Passion for building robust systems that are engineered to handle failure scenarios, an undying love and attitude for maintaining coding standards.
                            </li>
                            <li>
                                Strong advocate for producing quality software who makes sure issues are raised and resolved.
                            </li>
                            <li>
                                Container based application deployment - Docker or equivalent.
                            </li>
                            <li>
                                Experience with at least one of the cloud platforms like AWS, GCP, Azure, Digital Ocean etc.
                            </li>
                            <li>
                                Experience with GraphQL ecosystem a plus.
                            </li>
                            <li>
                                Server side language NodeJS and PHP with experience in any framework.
                            </li>
                            <li>
                                Frontend ReactJS, JS and HTML.
                            </li>
                            <li>
                                MYSQL/MARIADB Database.
                            </li>
                            <li>
                                Strong knowledge of OOPs Concepts.
                            </li>
                            <li>
                                Good to have familiarity with NoSql DB, caching (Memcache / Redis), NodeJs, Microservices architecture Familiar with agile software development methodologies (e.g. SCRUM / Agile / Kanban).
                            </li>
                            <li>
                                Understanding accessibility and security compliance.
                            </li>
                            <li>
                                Understanding fundamental design principles behind a scalable application.
                            </li>
                            <li>
                                Knowledge of using version control software, GIT will be preferable.
                            </li>
                            <li>
                                Minimum 4 years of demonstrable hands-on work in web-based application development experiences in a professional environment, including interaction design for dynamic, data-driven applications (not HTML based website) delivered via the web, or the equivalent.
                            </li>
                        </ul>

                        <h6 className='fw-semibold'>Years of experience:</h6>
                        <ul>
                            <li>
                                4+ years into development
                            </li>
                            <li>
                                1+ year into unit testing
                            </li>
                        </ul>

                        <h6 className='fw-semibold'>Education Qualification:</h6>
                        <p>Bachelor's Degree in Computer Science/Software Engineering Bachelor's degree or higher education.</p>

                        <h6 className='fw-semibold'>Job Type:</h6>
                        <ul>
                            <li>
                                Full-time
                            </li>
                        </ul>

                        <h6 className='fw-semibold'>Salary:</h6>
                        <ul>
                            <li>
                                Up to ₹15,00,000 a year
                            </li>
                        </ul>

                        <h6 className='fw-semibold'>Benefits:</h6>
                        <ul>
                            <li>
                                Paid sick time
                            </li>
                        </ul>

                        <h6 className='fw-semibold'>Shift and Schedule:</h6>
                        <ul>
                            <li>
                                Fixed shift
                            </li>
                            <li>
                                Monday to Friday
                            </li>
                        </ul>

                        <h6 className='fw-semibold'>Functional Area:</h6>
                        <ul>
                            <li>
                                HealthCare
                            </li>
                        </ul>
                        <br />
                        <NavLink to="/careers"><Button variant="outline-primary" id="button-addon1">  Back to all Jobs </Button></NavLink>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FullStackSoftwareDeveloper