import React from 'react';
import { NavLink } from 'react-router-dom';
import PageBanner from '../components/PageBanner';
import NewsUpdatesDetailPage from '../components/NewsUpdatesDetailPage';
import NewsUpdatesBackButton from '../components/NewsUpdatesBackButton';

const NewsUpdatesContent6 = () => {
    const newsContent = {
        title: 'ImagineSoftware and openDoctor Announce a Strategic Partnership Streamlining Patient Engagement',
        paragraphs: [
            <>
                <strong>CHARLOTTE, NC: June 2020 - </strong>ImagineSoftware and openDoctor announce a Strategic Partnership streamlining patient engagement by integrating openDoctor's portal with ImagineSoftware's insurance eligibility processing service, contactless patient payment tool and patient responsibility estimator.
            </>,
            <>
                ImagineSoftware and openDoctor today announced the availability of seamless integration between the openDoctor patient portal and ImagineSoftware Eligibility processing service. The direct connection between ImagineSoftware's Eligibility processing service and openDoctor's portal, allows organizations to quickly check patient eligibility within openDoctor's system either as an automated batch or via real-time requests, Additionally, patients will have the ability to make payments directly within openDoctor's system, streamlining the patient experience workflow.
            </>,
            <>
                Nick DiCristo, Vice President of Business Development, ImagineSoftware, said, “People are at the center of all we do at ImagineSoftware and this includes our valued partners. ImagineSoftware Preferred Partners become part of the Imagine family as they help us bring superior, innovated software solutions to our clients. We are proud to have openDoctor as part of our team. Alongside openDoctor, our goal is to improve our client's day-to-day workflow with seamlessly integrated solutions and unmatched client support.”
            </>,
            <>
                ImagineSoftware is committed to growing its preferred partner community with the implementation of integrated solutions. Preferred partners join an elite group of successful businesses and receive access to ImagineSoftware products, robust training, and top of the line marketing materials to enhance their business experience.
            </>,
            <>
                openDoctor is expanding its footprint in outpatient imaging, providing real-time patient access, referring engagement tools specifically designed to improve workflow and operational efficiency. The company goal is to bridge the communication gaps between imaging centers, referring providers and patients by providing a unified platform for sharing data.
            </>,
            <>
                Joe Marino, CEO, openDoctor, said, “We share the same goal as ImagineSoftware which is to improve our client's day-to-day workflow by automating scheduling and intake processes. With our partnership, we will also provide patients clarity on their insurance benefits and price estimations throughout the digital patient journey. It is an improved process to our clients by allowing patients the ability to make payments at the point of online scheduling, during registration and check-in and post appointments from the dashboard.”
            </>,
            <>
                ImagineSoftware is the leading provider of medical billing automation software and revenue cycle management applications. Offering powerful technology solutions for medical billing offices, practices, and hospitals for over 20 years, ImagineSoftware currently serves more than 48,000 physicians. ImagineSoftware solutions improve financial efficiency, build provider reputation, and ultimately improve the patient experience. For more information, visit <NavLink to="https://www.imagineteam.com/" target="_blank">www.imagineteam.com</NavLink> and follow us on Twitter @ImagineTeam.
            </>,
            <>
                <div className='col-md-12 text-center'>
                    <NewsUpdatesBackButton />
                </div>
            </>
        ],
    };

    return (
        <div>
            <PageBanner title="News and Updates" />
            <NewsUpdatesDetailPage title={newsContent.title} paragraphs={newsContent.paragraphs} />
        </div>
    )
}
export default NewsUpdatesContent6