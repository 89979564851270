import React from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import PageBanner from '../components/PageBanner';
import NewsUpdatesDetailPage from '../components/NewsUpdatesDetailPage';
import NewsUpdatesBackButton from '../components/NewsUpdatesBackButton';

const NewsUpdatesCaseStudy2 = () => {
    const newsContent = {
        title: 'University Radiology Makes Strategic Investment in openDoctor',
        paragraphs: [
            <>
                University Radiology, New Jersey's largest provider of subspecialty radiology services, today announced it has made a strategic investment in openDoctor, a leading provider of real-time access and engagement tools for ambulatory outpatient services.
            </>,
            <>
                <div className='col-md-12 text-center'>
                    <NewsUpdatesBackButton />
                    <NavLink to="https://www.benzinga.com/pressreleases/17/04/p9354235/university-radiology-makes-strategic-investment-in-opendoctor" target="_blank"><Button className="button large orange mx-0 mx-md-3">READ THE FULL ARTICLE</Button></NavLink>
                </div>
            </>
        ],
    };

    return (
        <div>
            <PageBanner title="News and Updates" />
            <NewsUpdatesDetailPage title={newsContent.title} paragraphs={newsContent.paragraphs} />
        </div>
    )
}
export default NewsUpdatesCaseStudy2