import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PageBanner from '../components/PageBanner';


const RemindersNotifications = () => {
    return (
        <div>
            <PageBanner title="openDoctor Reminders & Notifications Solution" text="" />
            <Container fluid className='p-5'>
                <Row className='justify-content-md-center'>
                    <Col lg={8}>
                        <h6 className='fw-semibold'>openReminders: Sophisticated reminders engine </h6>
                        <br />
                        <p>Send automated text, email communications for appointment reminders, digital registration, patient surveys, and more.</p>
                        <br />
                        <p>Includes patient notifications via email and SMS messaging for all patients, not just those who booked online. Notifications include reminders and pre-appointment instructions such as prep instructions</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default RemindersNotifications