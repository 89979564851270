import React from 'react';
import PageBanner from '../components/PageBanner';
import MidTitle from '../components/MidTitle';
import TeamContent from '../components/TeamContent';

export const Company = () => {
    return (
        <div>
            <PageBanner title="About openDoctor" text="The values and people behind openDoctor" />
            <MidTitle title="Healthcare, meets consumerism" text="As early innovators and evangelist in the consumerism of healthcare, we bring the right amount of passion and experience to an industry in swift transition. We are a team of seasoned technologists, designers, and strategists, we are obsessed with utilizing the right technology to bring a great patient experience to the healthcare industry." />
            <TeamContent />
        </div>
    );
}
export default Company;