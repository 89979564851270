import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PageBanner from '../components/PageBanner';
import opendrimg from '../images/opendr-8.png';

const EnterpriseSolution = () => {
    return (
        <div>
            <PageBanner title="openDoctor Enterprise Solution" text="" />
            <Container fluid className='p-lg-5 p-sm-4 mt-3 mt-lg-3 mt-sm-3'>
                <Row className='justify-content-md-center'>
                    <Col lg={4}>
                        <h6 className="fw-semibold">openDoctor origins have been forged in the radiology space, working closely with the leading radiology practices around the country.</h6>
                        <p className="mt-5">We began our journey with patient access and engagement in mind, and have now come full circle by creating innovative digital tools that create an interlocking network comprised of the patient, referring provider and the radiology practice. Our solutions are designed to equip you with a strategy to target efficiency through automated workflow management of your network. We are working with partners to bring cutting edge digital tools that meet real-world challenges in the complex world of radiology.</p>                    </Col>
                    <Col lg={4} className="text-end text-sm-center">
                        <img width={400} alt="Scheduling" src={opendrimg} />
                    </Col>
                </Row>

                <Row className='justify-content-md-center'>
                    <Col lg={8} className="pt-5">
                        <h6 className="fw-semibold">Real-time, online appointment scheduling</h6>
                        <p>Allows patients to self-schedule all modalities online from your website. Orders-Driven Process allows patients to receive a text to schedule online when the order is ready for scheduling.
                            openDoctor is for both new and existing patients for all client healthcare related services. Available for:</p>
                        <ul>
                            <li>
                                Consumers/Patients
                            </li>
                            <li>
                                Referring Providers
                            </li>
                            <li>
                                Internal Staff/Call Centers
                            </li>
                        </ul>

                        <h6 className="fw-semibold">RIS/EMR Integration</h6>
                        <ol>
                            <li>
                                Seamlessly enroll patient into RIS/EMR
                            </li>
                            <li>
                                Keep patient information synchronised between your and openDoctor's system
                            </li>
                            <li>
                                Receive and process orders, promt patients to schedule based on their orders
                            </li>
                            <li>
                                Real-time synchronization of appointments created on openDoctor into your system and vice versa
                            </li>
                            <li>
                                Intake of digital forms directly into your document management system
                            </li>
                            <li>
                                Provide patients and referring providers instant access to reports
                            </li>
                        </ol>

                        <p>Prior Auth Integration</p>

                        <h6 className="fw-semibold">Orders Driven Workflow: Proactive Communication</h6>
                        <p>An embedded feature within openSchedules which allows openDoctor to read the order and trigger an email and/or text to the patient to schedule online</p>
                        <br />
                        <h6 className="fw-semibold">openReferring: Referring Provider scheduling</h6>
                        <p>An embedded feature within openSchedules for referring physicians to e-order and schedule patients with the Client.</p>
                        <br />
                        <h6 className="fw-semibold">Website Patient Chat: Live patient support through openSchedules</h6>
                        <p>The website chat functionality to assist patients in scheduling appointments with live agents from openDr. Included will be reports on the patients experience to increase utilization and user feedback.</p>
                        <br />
                        <h6 className="fw-semibold">Patient Images: Post-appointment link to view images.</h6>
                        <p>Send patient post appointment reports and images</p>
                        <p>Embedded link in openDoctor post appointment reports to allow patients access to view the post appointment images.</p>
                        <br />
                        <h6 className="fw-semibold">openReports: Post-appointment online reports</h6>
                        <p>openDoctor's proprietary real-time transfer of patient results and images for referring providers and patients.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default EnterpriseSolution